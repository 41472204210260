import {
  PLACE_ORDER,
  SAVE_ORDERS,
  SAVE_ALL_ORDERS,
  SAVE_SELECTED_ORDERS,
  CLEAR_ORDER_STATE,
  SET_PAYMENT_DETAILS,
  SET_IS_SUCCESS
} from '../types/Order.type'

const initState = {
  currentOrder: {},
  orders: [],
  allOrders: [],
  selectedOrders: {},
  paymentDetails: null,
  issuccess: null
}

export default (state = initState, action) => {
  const { type, payload } = action
  console.log(payload, "order_reducer_payload")
  switch (type) {
    case PLACE_ORDER:
      return Object.assign({}, state, { currentOrder: payload })
    case SAVE_ORDERS:
      return Object.assign({}, state, { orders: payload })
    case SAVE_ALL_ORDERS:
      return Object.assign({}, state, { allOrders: payload })
    case SAVE_SELECTED_ORDERS:
      return Object.assign({}, state, { selectedOrders: { ...payload } })
    case SET_PAYMENT_DETAILS:
      return Object.assign({}, state, { paymentDetails: payload })
    case CLEAR_ORDER_STATE:
      return Object.assign({}, initState)
    case SET_IS_SUCCESS:
      return Object.assign({}, state, { issuccess: payload })
    default:
      return state
  }
}
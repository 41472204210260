import React from "react";
import { withRouter } from "../../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import { format } from 'date-fns';
import $ from "jquery";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import moment from "moment";
import CallCenterService from "../../../services/CallCenter.service";
import OrderAction from "../../../redux/actions/Order.action";
import OtherService from "../../../services/OtherService";
import CustomerAction from "../../../redux/actions/Customer.action";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import CountryService from '../../../services/Country.service';
import Dropdown from '../../../components/SearchableDropdown/Dropdown.component';
import DropdownDoctor from '../../../components/SearchableDropdown/DropdownDoctor.component';
import CommonAction from "../../../redux/actions/Common.action";
import OrderCancelModal from "../../../components/Order/OrderCancelModal";
import Summary from "../../../components/Summary/Summary.component";
import CallCenterAction from "../../../redux/actions/CallCenter.action";
import { ExportToExcel } from '../../../components/Excel/ExportToExcel'
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminSidebar from "../../../components/layout/Admin/AdminSidebar";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import '../../../assets/css/admin/style.css';
import '../../../assets/css/admin/responsive.css';
import config from "../../../config/emrok.config";
import AdminAction from "../../../redux/actions/Admin.action";
import StockistService from "../../../services/Stockist.service";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
class OrderManagment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            callcenterdetails: null,
            admin: null,
            totalRevenue: 0,
            storeSearchData: null,
            customerid: "",
            scallcenterid: "",
            callcenterList: [],
            callcenterinfo: "",
            callcenterdetailsList: [],
            countries: [],
            states: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedTown: "",
            selectedDoctor: "",
            fromdate: "",
            todate: "",
            currentDate: this.getCurrentDate(),
            doctorList: [],
            isSearch: false,
            isShowCancelModal: false,
            cancelOrderId: null,
            cancelReason: "",
            mobileno: "",
            summaryCols: ["Total Customers", "Total Registrations", "Total Orders", "Orders Pending", "Orders Delivered"],
            summaryDetails: null,
            setorderStatus: "",
            exportData: null,
            fileName: "Callcenter_Data",
            allStockists: [],
            selectedStockist: "",
            exportDropdown: [
                {
                    id: 1,
                    label: "Export Current View",
                    value: 1
                },
                {
                    id: 2,
                    label: "Export All Data",
                    value: 2
                }
            ],
            selectedExportItem: null,
            clickType: "",
            isMarkAsDelivered: false,
            selectedMarkAsDeliveredID: "",
            selectedMarkAsDeliveredFile: null,
            selectedOrderStatus: "",
            finalListData: [],
            remark: ""
        }
        this.copyState = this.state
        this.pageInfo = null
    }

    static getDerivedStateFromProps(props, state) {
        console.log(props.allStockists, 989898);

        return {
            callcenterdetails: props._id || null,
            admin: props._id || null,
            summaryDetails: props.summaryDetails || null,
            storeSearchData: props.storeSearchData || null,
            allStockists: props.allStockists || []
        }
    }

    getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    getSummary = async () => {

        const { fromdate, todate, selectedState, selectedTown, setorderStatus, selectedStockist, clickType } = this.state.storeSearchData;
        const selectedOnlyDateFrom = fromdate ? moment(fromdate).format('YYYY-MM-DD') : "";
        const selectedOnlyDateTo = todate ? moment(todate).format('YYYY-MM-DD') : "";
        let payload = {
            type: "CALLCENTER",
            fromDate: fromdate ? selectedOnlyDateFrom : "",
            toDate: todate ? selectedOnlyDateTo : "",
            stateName: selectedState,
            town: selectedTown,
            stockist_id: selectedStockist,
            orderStatus: setorderStatus,
            clickType: clickType

        }

        console.log(payload, "payload")
        OtherService.getSummary(payload).then(data => {
            console.log(data, "getSummary");
        }).catch(err => {
            console.log(err, "getSummary err");
        })
    }

    clickFn = async (index) => {
        console.log(index, "lkllklklk");
        const callcenterdetails = this.props.admin;
        let payload = {};
        let orderstatus = ""
        if (callcenterdetails) {
            // let payload = await {

            // }
            const { fromdate, todate, selectedState, selectedTown, selectedStockist, setorderStatus } = this.state.storeSearchData;
            const selectedOnlyDateFrom = fromdate ? moment(fromdate).format('YYYY-MM-DD') : "";
            const selectedOnlyDateTo = todate ? moment(todate).format('YYYY-MM-DD') : "";

            if (index === 0) {
                payload = {
                    type: "CALLCENTER",
                    callcenter_id: callcenterdetails._id,
                    fromDate: fromdate ? selectedOnlyDateFrom : "",
                    toDate: todate ? selectedOnlyDateTo : "",
                    stateName: selectedState,
                    town: selectedTown,
                    stockist_id: selectedStockist,
                    orderStatus: setorderStatus
                }
            } else {
                payload = {
                    type: "CALLCENTER",
                    callcenter_id: callcenterdetails._id,
                    fromDate: fromdate ? selectedOnlyDateFrom : "",
                    toDate: todate ? selectedOnlyDateTo : "",
                    stateName: selectedState,
                    town: selectedTown,
                    stockist_id: selectedStockist,
                    orderStatus: setorderStatus
                }
            }
            if (index === 0) {
                payload["orderStatus"] = ""
                payload["clickType"] = 'customer'
                orderstatus = ""
                this.setState({ setorderStatus: "", clickType: 'customer' })
            } else if (index === 1) {
                payload["orderStatus"] = ""
                payload["clickType"] = 'registration'
                orderstatus = ""
                this.setState({ setorderStatus: "", clickType: 'registration' })
            }
            else if (index === 2) {
                payload["orderStatus"] = ""
                payload["clickType"] = ''
                orderstatus = ""
                this.setState({ setorderStatus: "", clickType: '' })
            }
            else if (index === 3) {
                payload["orderStatus"] = await "pending"
                payload["clickType"] = ''
                orderstatus = "pending"
                this.setState({ setorderStatus: "pending", clickType: '' })

            }
            else if (index === 4) {
                payload["orderStatus"] = await "delivered"
                payload["clickType"] = ''
                orderstatus = "delivered"
                this.setState({ setorderStatus: "delivered", clickType: '' })
            }
            const storeSearchData = this.state.storeSearchData;
            storeSearchData.setorderStatus = orderstatus;
            storeSearchData.callcenterdetailsList = [];
            this.props.setSearchValue(storeSearchData);
            this.getOrderManagementDetails(payload)
        }
    }

    formatData(auditdata = null, pageInfo = null) {

        console.log(auditdata, auditdata.length, "auditdata")
        let formatedArr = [];
        let allfeedback = [];
        let feedbackString = "";
        const { clickType } = this.state
        for (let i = 0; i < auditdata.length; i++) {

            if (clickType == '') {

                const materials = auditdata[i].products.map(product => product.name);
                // Create a comma-separated string from the "material" values
                const materialString = materials.join(', ');
                const totalqty = auditdata[i].products.reduce((totalQuantity, product) => totalQuantity + product.quantity, 0);
                console.log(materials, materialString, totalqty, "popopopopo")
                // const feedbackStringFinal = allfeedback.length > 0 ? allfeedback.join(', ') : "";
                console.log(auditdata, "jhjhjauditdatahjhjh");
                try {

                    formatedArr.push({
                        "Registration Date": auditdata[i].customer.createdAt ? moment(auditdata[i].customer.createdAt).format('DD-MM-YYYY') : "",
                        "Order Number": auditdata[i].order_id || "",
                        "Order Date": auditdata[i].createdAt ? moment(auditdata[i].createdAt).format('DD-MM-YYYY') : "",
                        // "Registration No.": auditdata[i].customer.patient_id || "",
                        "Patient Name": auditdata[i].customer.name || "",
                        "Patient Age": auditdata[i].customer.age || "",
                        "Patient Gender": auditdata[i].customer.gender ? auditdata[i].customer.gender.charAt(0).toUpperCase() + auditdata[i].customer.gender.slice(1).toLowerCase() : "",
                        "Patient Mobile Number": auditdata[i].customer.mobileno || "",
                        "Patient Email": auditdata[i].customer.email || "",
                        "Patient Address": auditdata[i].address.addressLine1 || "",
                        "Patient City": auditdata[i].address.town || "",
                        "Patient State": auditdata[i].address.state || "",
                        "Patient Pin Code": auditdata[i].address.pin || "",
                        "Dr. Name": auditdata[i].doctor.drName || "",
                        "Dr. Pin Code": auditdata[i].doctor.pin || "",
                        "Dr. Registration Number": auditdata[i].doctor.drRegNo || "",
                        "Dr. Mobile Number": auditdata[i].doctor.mobileno || "",
                        "Product Name": materialString,
                        "Product Quantity": totalqty + (auditdata[i].free_pen_quantity ? auditdata[i].free_pen_quantity : 0),
                        "Order Value": auditdata[i].total || "",
                        "Free Gift": auditdata[i].free_pen_quantity || 0,
                        "Stockist Name": auditdata[i].stockist ? auditdata[i].stockist.name : "" || "",
                        "Stockist Code": auditdata[i].stockist ? auditdata[i].stockist.stockist_code : "" || "",
                        "Payment Status": auditdata[i].payment_status ? auditdata[i].payment_status.charAt(0).toUpperCase() + auditdata[i].payment_status.slice(1).toLowerCase() : "",
                        // "Prescription": auditdata[i].prescription ? auditdata[i].prescription.filepath : "",
                        // "Invoice": "",
                        "Delivery Date": auditdata[i].deliveryDate ? moment(auditdata[i].deliveryDate).format('DD-MM-YYYY') : "",
                        "Remark": auditdata[i].remark ? auditdata[i].remark : "",
                        "Order Status": auditdata[i].orderStatus ? auditdata[i].orderStatus.charAt(0).toUpperCase() + auditdata[i].orderStatus.slice(1).toLowerCase() : ""
                    })
                    console.log(formatedArr, 8787878787);
                } catch (error) {
                    console.log(error, 8787878787);
                }

            } else {
                try {
                    formatedArr.push({
                        "Registration Date": auditdata[i].createdAt ? moment(auditdata[i].createdAt).format('DD-MM-YYYY') : "",
                        "Patient Name": auditdata[i].name || "",
                        "Patient Age": auditdata[i].age || "",
                        "Patient Gender": auditdata[i].gender ? auditdata[i].gender.charAt(0).toUpperCase() + auditdata[i].gender.slice(1).toLowerCase() : "",
                        "Patient Mobile Number": auditdata[i].mobileno || "",
                        "Patient Email": auditdata[i].email || "",
                        "Patient Address": auditdata[i].address ? auditdata[i].address.addressLine1 : "",
                        "Patient City": auditdata[i].address ? auditdata[i].address.town : "",
                        "Patient State": auditdata[i].address ? auditdata[i].address.state : "",
                        "Patient Pin Code": auditdata[i].address ? auditdata[i].address.pin : "",
                        "Dr. Name": auditdata[i].doctor.length > 0 ? auditdata[i].doctor[auditdata[i].doctor.length - 1].drName : "",
                        "Dr. Pin Code": auditdata[i].doctor.length > 0 ? auditdata[i].doctor[auditdata[i].doctor.length - 1].pin : "",
                        "Dr. Registration Number": auditdata[i].doctor.length > 0 ? auditdata[i].doctor[auditdata[i].doctor.length - 1].drRegNo : "",
                        "Dr. Mobile Number": auditdata[i].doctor.length > 0 ? auditdata[i].doctor[auditdata[i].doctor.length - 1].mobileno : ""

                    })
                    console.log(formatedArr, 8787878787);
                } catch (error) {
                    console.log(error, 8787878787);
                }
            }
        }
        console.log(formatedArr, "formatedArr111");
        if (pageInfo && formatedArr.length) {
            let response = []
            const limit = pageInfo.rowsPerPage;
            const offset = (pageInfo.currentPage - 1) * pageInfo.rowsPerPage;
            response = formatedArr.slice(offset, offset + limit)
            return response
        }

        return formatedArr;


    }

    getOrderManagementDetails = (details) => {

        console.log(details, "getOrderManagementDetails");
        // OrderService.getOrderManagementDetails(details).then(data)

        CallCenterService.showLoader();

        this.setState({ callcenterdetailsList: [] }, () => {
            CallCenterService.getCallCenterDetails(details).then(data => {
                console.log(data.data, "jhjhjhjhjhjhjhj");

                if (data.data.success && data.data.data.callcenterdetails !== '') {
                    console.log(data.data.data.callcenterdetails, "okkkkk12");

                    const formatedData = this.formatData(data.data.data.callcenterdetails);
                    console.log(formatedData, "formatedData")
                    // return
                    this.setState({ exportData: formatedData });
                    const currentDateTime = moment().format('DD-MM-YYYY H:m:s');
                    const filename = "Callcenter_Data" + currentDateTime;
                    this.setState({ filename: filename });
                    // if(!this.state.isSearch){
                    // this.getDoctors(data.data.data.callcenterdetails);

                    setTimeout(() => {
                        this.setState({ callcenterdetailsList: data.data.data.callcenterdetails });
                    }, 500);

                    this.setOrderHistoryData(data.data.data.callcenterdetails)

                    const storeSearchData = this.state.storeSearchData;
                    storeSearchData.exportData = formatedData;
                    storeSearchData.filename = filename;
                    // if (storeSearchData.callcenterdetailsList.length == 0) {
                    // storeSearchData.callcenterdetailsList = data.data.data.callcenterdetails;
                    // }

                    if (storeSearchData.callcenterdetailsList.length) {
                        storeSearchData.callcenterdetailsList = storeSearchData.callcenterdetailsList;
                    } else {
                        storeSearchData.callcenterdetailsList = data.data.data.callcenterdetails;
                    }

                    this.props.setSearchValue(storeSearchData);
                    this.countTotalRevenue();
                }



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })
        });


        //  CallCenterService.hideLoader();
    }

    componentDidMount() {
        this.getStockists()
        const callcenterdetails = this.props.admin;
        console.log(callcenterdetails, "callcenterdetails");


        const callcenter_id = callcenterdetails._id;
        const clickData = this.props.activeDashboardClick;

        if (clickData != "") {
            this.setState({ clickType: clickData });

            this.props.setActiveDashboardClick('');
        } else {
            this.setState({ clickType: '' });
        }

        console.log(clickData, "clickData1233");
        let details = {
            callcenter_id: callcenter_id,
            clickType: clickData
        };
        console.log(details, "callcenter details 1",);
        if (callcenterdetails) {


            CallCenterService.getCallCenterInfo(details).then(data => {
                console.log(data.data, "callcenter info");

                if (data.data.success && data.data.data.callcenterinfo !== '') {
                    console.log(data.data.data.callcenterinfo[0], "okkkkk");

                    // const stateDetails = this.state.setSearchValue;
                    //this.props.setSearchValue(this.state);

                    // if(!this.state.isSearch){
                    this.setState({ callcenterinfo: data.data.data.callcenterinfo[0] });
                    const storeSearchData = this.state.storeSearchData;
                    storeSearchData.callcenterinfo = data.data.data.callcenterinfo[0];

                    // if(storeSearchData.callcenterinfo){

                    // }
                    this.props.setSearchValue(storeSearchData);
                    // }

                }

                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })

            this.getOrderManagementDetails(details)

            this.countTotalRevenue();
            // return

            setTimeout(() => {
                this.populateStates("IN")
                // this.populateStates(countryObj.isoCode)	
            }, 500);

            let detailsBody = {
                id: callcenter_id,
                type: "callcenter"
            };
            this.getSummary()
        } else {
        }
    }

    countTotalRevenue = () => {
        const storeSearchData = this.state.storeSearchData;
        const callCenterListData = storeSearchData.callcenterdetailsList
        const totalqty = callCenterListData.reduce((totalQuantity, callcenter) => totalQuantity + parseFloat(callcenter.total), 0);
        const twoDecimel = totalqty ? totalqty.toFixed(2) : 0;
        this.setState({ totalRevenue: twoDecimel });
        // console.log(totalqty,666666)
    }
    //ayan
    getStockists = () => {
        StockistService.getAll().then(data => {
            console.log(data, "getStockists");
        })
    }

    getDoctors = (doctors) => {

        // Create an array to store the grouped data
        const groupedData = [];
        const finalData = [];

        // Create an object to keep track of seen doctor._id values
        const seenDoctorIds = {};

        // Use forEach to iterate through the data
        doctors.forEach((currentValue) => {
            const doctorId = currentValue.doctor._id;

            // Check if we've already seen this doctor._id
            if (!seenDoctorIds[doctorId]) {
                // Add the current value to the groupedData array
                groupedData.push(currentValue.doctor);

                // Mark the doctor._id as seen
                seenDoctorIds[doctorId] = true;
            }
        });

        for (let index = 0; index < groupedData.length; index++) {
            finalData[index] = {
                "name": groupedData[index].name,
                "label": groupedData[index].name,
                "value": groupedData[index]._id,
                "id": groupedData[index]._id,
            };


        }


        const allOption = {
            "name": "All",
            "label": "All",
            "value": "All",
            "id": "All"
        };

        let optionsWithAll = [allOption, ...finalData];


        console.log(finalData, "finalData");
        // Now 'groupedData' contains unique doctors with a regular index

        this.setState({ doctorList: optionsWithAll });

        const storeSearchData = this.state.storeSearchData;
        if (storeSearchData.doctorList.length == 0) {
            storeSearchData.doctorList = optionsWithAll;
            this.props.setSearchValue(storeSearchData);
        }


        console.log(groupedData, "groupedData")

        // Now 'groupedData' contains the data grouped by doctor._id


    }

    populateStates = (isoCode = null) => {
        if (isoCode) {
            CountryService.getStates(isoCode).then(data => {
                //console.log(data, "states")
                if (data.success) {
                    const storeSearchData = this.state.storeSearchData;
                    storeSearchData.states = data.states;

                    console.log(storeSearchData.states, 6666666);
                    this.props.setSearchValue(storeSearchData);
                    this.setState({ states: data.states, towns: [] }, async () => {
                        this.setState({ showTowns: false })
                        let _state = this.state.storeSearchData.selectedState || null
                        let _states = this.state.storeSearchData.states || []
                        console.log(_state, this.state.storeSearchData.states, "jhgjhgjhg");
                        if (_state) {
                            // alert("ok");
                            let stateObj = null
                            let stateObjAll = null
                            for (let i = 0; i < this.state.storeSearchData.states.length; i++) {

                                if (this.state.states[i].name === _state) {
                                    stateObj = this.state.storeSearchData.states[i]
                                }
                            }


                            console.log(stateObj, _state, 1234545677);
                            console.log("stateObj found", stateObj)
                            // if (stateObj) {
                            setTimeout(() => {
                                if (stateObj != null && stateObj.countryCode)
                                    this.populateTowns(stateObj.countryCode, stateObj.isoCode)
                            }, 500);
                            // }
                        }
                    })


                    setTimeout(() => {
                        let stateDetails = this.state.storeSearchData.states;

                        const findIfExistAll = stateDetails.find(item => item.name === 'All');

                        console.log(findIfExistAll, 33333);

                        if (!findIfExistAll) {
                            console.log(stateDetails, "details state all 1")
                            const allOption = {
                                "name": "All",
                                "isoCode": null,
                                "countryCode": null,
                                "latitude": null,
                                "longitude": null,
                                "label": "All",
                                "value": "all"
                            };
                            console.log(this.state.storeSearchData.states, "details state")
                            // Create a new array with the "All" option at the beginning
                            let optionsWithAll = [allOption, ...stateDetails];
                            console.log(optionsWithAll, "details state all")
                            this.setState({ states: optionsWithAll })
                            const storeSearchData = this.state.storeSearchData;
                            storeSearchData.states = optionsWithAll;
                            this.props.setSearchValue(storeSearchData);
                        }

                    }, 500);
                }
            })
        }
    }

    populateTowns = (countryCode = null, stateCode = null) => {
        console.log(countryCode, stateCode, "countryCode stateCode")
        if (countryCode && stateCode) {
            CountryService.getTowns(countryCode, stateCode).then(data => {
                console.log(data, "towns")
                // setTimeout(() => {
                if (data.success) {
                    const storeSearchData = this.state.storeSearchData;
                    storeSearchData.towns = data.towns;
                    this.props.setSearchValue(storeSearchData);
                    this.setState({ towns: data.towns }, () => this.setState({ showTowns: true }))

                    setTimeout(() => {
                        let stateDetails = this.state.storeSearchData.towns;
                        const allOption = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                        console.log(this.state.storeSearchData.towns, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll = [allOption, ...stateDetails];
                        console.log(optionsWithAll, "details state all")
                        this.setState({ towns: optionsWithAll })

                        const storeSearchData = this.state.storeSearchData;
                        storeSearchData.towns = optionsWithAll;
                        this.props.setSearchValue(storeSearchData);
                    }, 500);

                    // this.props.setSearchValue(this.state);
                }
                // }, 2000);
            })
        }
    }
    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }

    handleTrackOrder = (event) => {

        // const allOrder = this.props.OrderReducer.allOrders;
        const targetId = event.target.name;
        console.log(targetId, "target ID");
        try {
            OtherService.getOrderDetailsByIdTrack({ _id: targetId }).then(data => {
                console.log(data.data, "order details 123456789");
                if (data.data.success) {
                    console.log(data.data.data.order, "okkkkk 123456789");
                    this.props.saveSelectedOrders(data.data.data.order)
                    this.props.navigate('/admin/track-order');
                    this.props.setUserType('admin');
                    // this.setState({mrdetailsList:data.data.data.mrdetails});
                }
            })
        } catch (error) {

        }

    }

    changeValFromDate = async (date) => {

        // const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "fromdate");
        this.setState({ fromdate: date });
        const storeSearchData = this.state.storeSearchData;
        storeSearchData.fromdate = date;
        this.props.setSearchValue(storeSearchData);
    }

    changeValToDate = async (date) => {

        // const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "todate");
        this.setState({ todate: date });

        const storeSearchData = this.state.storeSearchData;
        storeSearchData.todate = date;
        this.props.setSearchValue(storeSearchData);
    }

    handleEditPatientDetails = async (event) => {
        const { clickType } = this.state
        event.preventDefault();
        const customer_id = event.target.id;
        const callCenterDetails = this.state.callcenterdetailsList;

        const adminLoginDetails = this.props.admin;

        console.log(customer_id, callCenterDetails, "selectedPatientDetails99999");
        const selectedPatientDetails = await callCenterDetails.filter(item => item._id == customer_id)
        console.log(selectedPatientDetails, "selectedPatientDetails with doctor25252525");
        // return
        if (selectedPatientDetails.length) {

            let customerDetailsObjNew = {};
            if (clickType != 'registration') {
                const allPrescription = selectedPatientDetails[0].prescription ? selectedPatientDetails[0].prescription : null
                customerDetailsObjNew = {
                    _id: selectedPatientDetails[0].customer._id,
                    customer_code: null,
                    country_code: "+91",
                    mobileno: selectedPatientDetails[0].customer.mobileno,
                    age: selectedPatientDetails[0].customer.age,
                    email: selectedPatientDetails[0].customer.email,
                    gender: selectedPatientDetails[0].customer.gender,
                    name: selectedPatientDetails[0].customer.name,
                    drName: selectedPatientDetails[0].doctor ? selectedPatientDetails[0].doctor.drName : "",
                    drRegistrationNo: selectedPatientDetails[0].doctor ? selectedPatientDetails[0].doctor.drRegNo : "",
                    drPin: selectedPatientDetails[0].doctor ? selectedPatientDetails[0].doctor.pin : "",
                    drmobileno: selectedPatientDetails[0].doctor ? selectedPatientDetails[0].doctor.mobileno : "",
                    drState: selectedPatientDetails[0].doctor ? (selectedPatientDetails[0].doctor.drState ? selectedPatientDetails[0].doctor.drState : "") : "",
                    drCity: selectedPatientDetails[0].doctor ? (selectedPatientDetails[0].doctor.drCity ? selectedPatientDetails[0].doctor.drCity : "") : "",
                    address: {
                        _id: selectedPatientDetails[0].address ? selectedPatientDetails[0].address._id : "",
                        country: selectedPatientDetails[0].address ? selectedPatientDetails[0].address.country : "",
                        addressLine1: selectedPatientDetails[0].address ? selectedPatientDetails[0].address.addressLine1 : "",
                        addressLine2: selectedPatientDetails[0].address ? selectedPatientDetails[0].address.addressLine2 : "",
                        town: selectedPatientDetails[0].address ? selectedPatientDetails[0].address.town : "",
                        state: selectedPatientDetails[0].address ? selectedPatientDetails[0].address.state : "",
                        pin: selectedPatientDetails[0].address ? selectedPatientDetails[0].address.pin : "",
                    },
                    doctor_id: selectedPatientDetails[0].doctor ? selectedPatientDetails[0].doctor._id : "",
                    consent: selectedPatientDetails[0].customer.consent ? selectedPatientDetails[0].customer.consent : false,
                    prescription: allPrescription,
                    order_id: selectedPatientDetails[0]._id
                };

            } else {
                const allPrescription = selectedPatientDetails[0].prescription ? selectedPatientDetails[0].prescription : null
                customerDetailsObjNew = {
                    _id: selectedPatientDetails[0]._id,
                    customer_code: null,
                    country_code: "+91",
                    mobileno: selectedPatientDetails[0].mobileno,
                    age: selectedPatientDetails[0].age,
                    email: selectedPatientDetails[0].email,
                    gender: selectedPatientDetails[0].gender,
                    name: selectedPatientDetails[0].name,
                    drName: selectedPatientDetails[0].doctor.length > 0 ? selectedPatientDetails[0].doctor[selectedPatientDetails[0].doctor.length - 1].drName : "",
                    drRegistrationNo: selectedPatientDetails[0].doctor.length > 0 ? selectedPatientDetails[0].doctor[selectedPatientDetails[0].doctor.length - 1].drRegNo : "",
                    drPin: selectedPatientDetails[0].doctor.length > 0 ? selectedPatientDetails[0].doctor[selectedPatientDetails[0].doctor.length - 1].pin : "",
                    drmobileno: selectedPatientDetails[0].doctor.length > 0 ? selectedPatientDetails[0].doctor[selectedPatientDetails[0].doctor.length - 1].mobileno : "",
                    drState: selectedPatientDetails[0].doctor ? (selectedPatientDetails[0].doctor.drState ? selectedPatientDetails[0].doctor.drState : "") : "",
                    drCity: selectedPatientDetails[0].doctor ? (selectedPatientDetails[0].doctor.drCity ? selectedPatientDetails[0].doctor.drCity : "") : "",
                    address: {
                        _id: selectedPatientDetails[0].address ? selectedPatientDetails[0].address._id : "",
                        country: selectedPatientDetails[0].address ? selectedPatientDetails[0].address.country : "",
                        addressLine1: selectedPatientDetails[0].address ? selectedPatientDetails[0].address.addressLine1 : "",
                        addressLine2: selectedPatientDetails[0].address ? selectedPatientDetails[0].address.addressLine2 : "",
                        town: selectedPatientDetails[0].address ? selectedPatientDetails[0].address.town : "",
                        state: selectedPatientDetails[0].address ? selectedPatientDetails[0].address.state : "",
                        pin: selectedPatientDetails[0].address ? selectedPatientDetails[0].address.pin : "",
                    },
                    doctor_id: selectedPatientDetails[0].doctor.length > 0 ? selectedPatientDetails[0].doctor[selectedPatientDetails[0].doctor.length - 1]._id : "",
                    consent: selectedPatientDetails[0].consent ? selectedPatientDetails[0].consent : false,
                    prescription: allPrescription,
                    order_id: null
                };
            }


            this.props.setCustomer(customerDetailsObjNew);
            this.props.setAdmin(adminLoginDetails);

            // console.log(this.props.callcenterdetails, "callcenter reducer")
            // this.props.setCallCenterCustomer(selectedPatientDetails[0].customer)
        } else {

        }

        this.props.navigate('/admin/registration-details-edit');
        // console.log(selectedPatientDetails);
    }
    searchFilter = (event) => {
        this.setState({ callcenterdetailsList: [] });
        const storeSearchData = this.state.storeSearchData;
        storeSearchData.callcenterdetailsList = [];
        this.props.setSearchValue(storeSearchData);
        const callcenterdetails = this.props.admin;
        const callcenter_id = callcenterdetails._id;
        const { fromdate, todate, selectedState, selectedTown, selectedStockist, setorderStatus } = this.state.storeSearchData;
        const { clickType } = this.state

        const selectedOnlyDateFrom = fromdate ? moment(fromdate).format('YYYY-MM-DD') : "";
        const selectedOnlyDateTo = todate ? moment(todate).format('YYYY-MM-DD') : "";
        let details = {
            callcenter_id: callcenter_id,
            fromDate: fromdate ? selectedOnlyDateFrom : "",
            toDate: todate ? selectedOnlyDateTo : "",
            stateName: selectedState,
            town: selectedTown,
            stockist_id: selectedStockist,
            orderStatus: setorderStatus,
            clickType: clickType
        }
        console.log(details, "pppppppppp");

        // return

        if (fromdate != "" && todate == "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else if (fromdate == "" && todate != "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else {

            CallCenterService.getCallCenterDetails(details).then(data => {
                console.log(data.data, "callcenter details 2 after filter");

                if (data.data.success && data.data.data.callcenterdetails !== '') {
                    console.log(data.data.data.callcenterdetails, "okkkkk after filter");
                    const formatedData = this.formatData(data.data.data.callcenterdetails);
                    console.log(formatedData, "formatedData")
                    this.setState({ exportData: formatedData });
                    const currentDateTime = moment().format('DD-MM-YYYY H:m:s');
                    const filename = "Callcenter_Data" + currentDateTime;
                    this.setState({ filename: filename });
                    this.getDoctors(data.data.data.callcenterdetails);
                    // setTimeout(() => {
                    this.setState({ callcenterdetailsList: data.data.data.callcenterdetails });
                    this.setState({ isSearch: true })

                    const storeSearchData = this.state.storeSearchData;
                    storeSearchData.exportData = formatedData;
                    storeSearchData.filename = filename;
                    storeSearchData.callcenterdetailsList = data.data.data.callcenterdetails;
                    storeSearchData.isSearch = true;
                    this.props.setSearchValue(storeSearchData);
                    // this.populateStates(countryObj.isoCode)	
                    // }, 500);
                    this.getSummary()


                } else {
                    this.getSummary()
                }
                this.countTotalRevenue();



                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {

                console.log(err, "err")
            })
        }




        console.log("search filter");
    }

    changeDoctor = (event) => {
        const doctorId = event.target.value;
        this.setState({ selectedDoctor: doctorId });
        console.log(doctorId, "doctorId");
        const storeSearchData = this.state.storeSearchData;
        storeSearchData.selectedDoctor = doctorId;
        this.props.setSearchValue(storeSearchData);
    }

    checkInvoiceGenerated = async (orderId, data) => {
        const order_id = orderId;
        console.log(order_id, data, "invoice generated")

        const state = data ? data.address.state : null
        const filePath = data ? data.invoiceFilePath : null

        console.log(state, "order_id1111");

        let downloadUrl = null
        if (state == "Maharashtra" || state == "Goa") {
            console.log(order_id, "order_id1111")
            downloadUrl = config.serviceUrl + "/images/sap/in/Invoice/" + filePath;
        } else {
            downloadUrl = config.serviceUrl + "/images/sap/in/Invoice/" + order_id + ".pdf"; // Replace with the actual URL or path of the file
        }
        // const downloadUrl = config.serviceUrl + "/images/sap/in/Invoice/" + order_id + ".pdf";
        console.log(downloadUrl, "downloadUrl");
        let isPresent = false;
        try {
            // Make a HEAD request to check if the file exists without downloading it
            const response = await fetch(downloadUrl, { method: 'HEAD' });
            console.log(response, 'PDF12333')
            if (response.ok) {
                isPresent = true;
                console.log("PDF is present");
            } else {
                isPresent = false;
                console.log("PDF not present");
            }
        } catch (error) {
            isPresent = false;
            console.error("Error occurred while checking PDF:", error);
        }

        return isPresent;
    }

    setOrderHistoryData = async (data) => {
        this.setState({ finalListData: [] })
        console.log(data, "89898767676");
        const { clickType } = this.state
        let resp = []
        let allfeedback = [];
        let feedbackString = "";
        for (let i = 0; i < data.length; i++) {
            if (clickType == '') {
                feedbackString = "";
                let check_isPresent = false;
                // allfeedback = [];
                let isPresent = await this.checkInvoiceGenerated(data[i].order_id, data[i])
                if (isPresent) {
                    console.log(isPresent, "isPresent123");
                    // Do something when the invoice is present
                    // resp['invoice'] = true
                    check_isPresent = true
                } else {
                    // resp['invoice'] = false
                    check_isPresent = false
                    // Do something when the invoice is not present
                }
                const materials = data[i].products.map(product => product.name);
                const totalqty = data[i].products.reduce((totalQuantity, product) => totalQuantity + product.quantity, 0);
                const materialString = materials.join(', ');
                resp.push({
                    _id: data[i]._id,
                    customer_id: data[i].customer._id,
                    customer_name: data[i].customer.name,
                    customer_mobile: data[i].customer.mobileno,
                    // customer_address: (data[i].address ? data[i].address.addressLine1 : "") + "," + (data[i].address ? data[i].address.town : "") + "," + (data[i].address ? data[i].address.state : "") + "," + (data[i].address ? data[i].address.country : "") + "," + (data[i].address ? data[i].address.pin : ""),
                    customer_address: (data[i].address ? data[i].address.addressLine1 : ""),
                    customer_state: (data[i].address ? data[i].address.state : ""),
                    customer_city: (data[i].address ? data[i].address.town : ""),
                    customer_age: (data[i].customer ? data[i].customer.age : ""),
                    customer_gender: (data[i].customer ? data[i].customer.gender.charAt(0).toUpperCase() + data[i].customer.gender.slice(1).toLowerCase() : ""),
                    customer_email: (data[i].customer ? data[i].customer.email : ""),
                    order_id: data[i].order_id,
                    allproducts: data[i].products,
                    order_status: data[i].orderStatus ? data[i].orderStatus : "",
                    order_date: data[i].createdAt ? data[i].createdAt : "",
                    delivery_date: data[i].deliveryDate ? data[i].deliveryDate : "",
                    doctor_name: data[i].doctor.drName,
                    doctor_registration_no: data[i].doctor.drRegNo,
                    doctor_mobileno: data[i].doctor.mobileno,
                    // mr_name: data[i].mr[0].name,
                    stockist_name: data[i].stockist ? data[i].stockist.name : "",
                    stockist_code: data[i].stockist ? data[i].stockist.stockist_code : "",
                    product_code: materialString,
                    total_product_qty: totalqty + (data[i].free_pen_quantity ? data[i].free_pen_quantity : 0),
                    total: data[i].total ? data[i].total : "",
                    // feedback: feedbackStringFinal,
                    prescription_path: data[i].prescription ? data[i].prescription.filepath : "",
                    fileExtention: data[i].prescription ? data[i].prescription.filepath.split('.')[1] : "",
                    payment_status: data[i].payment_status ? data[i].payment_status.charAt(0).toUpperCase() + data[i].payment_status.slice(1).toLowerCase() : "",
                    payment_type: data[i].payment_type ? data[i].payment_type : "",
                    registration_no: data[i].customer.patient_id || "",
                    registration_date: data[i].customer.createdAt || "",
                    customer_pin: data[i].address.pin || "",
                    doctor_pin: data[i].doctor.pin || "",
                    free_pen: data[i].free_pen_quantity || 0,
                    invoice: check_isPresent,
                    is_prescription_approved: data[i].is_prescription_approved || null,
                    markAsDeliveredFilePath: data[i].markAsDeliveredFile || null,
                    markAsDeliveredFilePath_extension: data[i].markAsDeliveredFile ? data[i].markAsDeliveredFile.split('.')[1] : null,
                    remark: data[i].remark || ""
                })

            } else {
                const prescriptionLength = data[i].prescription ? data[i].prescription.length : null;
                const allPrescription = data[i].prescription ? data[i].prescription[prescriptionLength - 1] : null
                resp.push({
                    _id: data[i]._id,
                    customer_id: data[i]._id,
                    customer_name: data[i].name || "",
                    customer_mobile: data[i].mobileno || "",
                    // customer_address: (data[i].address ? data[i].address.addressLine1 : "") + "," + (data[i].address ? data[i].address.town : "") + "," + (data[i].address ? data[i].address.state : "") + "," + (data[i].address ? data[i].address.country : "") + "," + (data[i].address ? data[i].address.pin : ""),
                    customer_address: (data[i].address ? data[i].address.addressLine1 : ""),
                    customer_state: (data[i].address ? data[i].address.state : ""),
                    customer_city: (data[i].address ? data[i].address.town : ""),
                    customer_age: (data[i].age ? data[i].age : ""),
                    customer_gender: (data[i].gender ? data[i].gender.charAt(0).toUpperCase() + data[i].gender.slice(1).toLowerCase() : ""),
                    customer_email: (data[i].email ? data[i].email : ""),
                    doctor_name: data[i].doctor.length > 0 ? data[i].doctor[data[i].doctor.length - 1].drName : "",
                    doctor_registration_no: data[i].doctor.length > 0 ? data[i].doctor[data[i].doctor.length - 1].drRegNo : data[i].doctor,
                    doctor_mobileno: data[i].doctor.length > 0 ? data[i].doctor[data[i].doctor.length - 1].mobileno : "",
                    doctor_pin: data[i].doctor.length > 0 ? data[i].doctor[data[i].doctor.length - 1].pin : "",
                    registration_no: data[i].patient_id || "",
                    registration_date: data[i].createdAt || "",
                    customer_pin: data[i].address ? data[i].address.pin : "",
                    prescription: allPrescription

                })
            }
        }

        setTimeout(() => {
            this.setState({ finalListData: resp })
        }, 800);
        console.log(resp, data, "4554545454")
        return resp

    }

    handleDownload = async (event) => {
        // alert(event.target.id)

        const selectOrder = event.target.id;

        const callCenterDetails = this.state.callcenterdetailsList;
        const selectedPatientDetails = await callCenterDetails.filter(item => item.order_id == selectOrder)
        let downloadUrl = null
        if (selectedPatientDetails[0].address.state == "Maharashtra" || selectedPatientDetails[0].address.state == "Goa") {
            downloadUrl = config.serviceUrl + "/images/sap/in/Invoice/" + selectedPatientDetails[0].invoiceFilePath;
        } else {
            downloadUrl = config.serviceUrl + "/images/sap/in/Invoice/" + selectOrder + ".pdf"; // Replace with the actual URL or path of the file
        }
        // const downloadUrl = config.serviceUrl + "/images/sap/in/Invoice/" + selectOrder + ".pdf"; // Replace with the actual URL or path of the file

        console.log(downloadUrl, "downloadUrl");
        try {
            const response = await fetch(downloadUrl);
            if (response.status === 200) {
                window.open(downloadUrl, '_blank');
                console.log("present")
            } else {
                // alert("not present")
                this.props.hoc.customAlert("Invoice not yet generated.", false)
                console.log("not present")
            }
        } catch (error) {
            window.open(downloadUrl, '_blank');
            // Handle network or other errors
            console.log("error");
        }

    };

    handleCancelOrder = (event) => {

        console.log(event.target.dataset.cid, 55555);
        const orderId = event.target.name;
        const mobileno = event.target.id;
        const customerid = event.target.dataset.cid;

        // alert(mobileno,"mobileno");
        this.setState({ isShowCancelModal: true, cancelOrderId: orderId, mobileno: mobileno, customerid: customerid });
        // this.setState({ cancelOrderId: orderId });
        // this.setState({ mobileno: mobileno });

        const storeSearchData = this.state.storeSearchData;
        storeSearchData.isShowCancelModal = true;
        storeSearchData.cancelOrderId = orderId;
        storeSearchData.mobileno = mobileno;
        storeSearchData.customerid = customerid;
        this.props.setSearchValue(storeSearchData);


    }
    handleOkClick = () => {
        const orderId = this.state.cancelOrderId;
        const callcenterdetails = this.props.admin;

        console.log(callcenterdetails, "callcenterdetails");

        const callcenter_id = callcenterdetails._id;
        const userType = callcenterdetails.userType;
        // const mobileno = this.props.callcenterdetails.customer.mobileno;

        let details = {
            order_id: orderId,
            user_id: callcenter_id,
            user_type: userType,
            reason: this.state.storeSearchData.cancelReason,
            mobileno: this.state.storeSearchData.mobileno,
            customer_id: this.state.storeSearchData.customerid


        };

        console.log(details, "details")
        let body = {
            callcenter_id: callcenter_id
        }

        if (this.state.storeSearchData.cancelReason != "") {
            OtherService.cancelOrder(details).then(data => {
                console.log(data.data, "cancel order");

                if (data.data.success) {
                    console.log(data.data, "data.data")
                    this.setState({ callcenterdetailsList: [] });
                    CallCenterService.getCallCenterDetails(body).then(data => {
                        console.log(data.data, "callcenter details 2");

                        if (data.data.success && data.data.data.callcenterdetails !== '') {
                            console.log(data.data.data.callcenterdetails, "okkkkk12");

                            // this.getDoctors(data.data.data.callcenterdetails);
                            this.setState({ callcenterdetailsList: data.data.data.callcenterdetails });
                            const storeSearchData = this.state.storeSearchData;
                            storeSearchData.callcenterdetailsList = data.data.data.callcenterdetails;
                            this.props.setSearchValue(storeSearchData);
                        }

                    }).catch(err => {

                        console.log(err, "err")
                    })

                    this.props.hoc.customAlert("Order rejected successfully.", true)

                }
                // this.setState({mrdetailsList:data.data.data.mrdetails});

            }).catch(err => {
                this.props.hoc.customAlert("Order rejected successfully.", true)
                console.log(err, "err")
            })
        } else {
            this.props.hoc.customAlert("Select reject reason", false);
        }






        // alert(callcenter_id);
    }

    handleRadioClick = (event) => {
        // alert("ok")
        //  alert(event.target.value);

        this.setState({ cancelReason: event.target.value });
        const storeSearchData = this.state.storeSearchData;
        storeSearchData.cancelReason = event.target.value;
        this.props.setSearchValue(storeSearchData);
    }

    downloadPdf = async (event) => {
        const order_id = event.target.id;

        const downloadUrl = config.serviceUrl + "/images/invoice/" + order_id + ".pdf"; // Replace with the actual URL or path of the file
        try {
            const response = await fetch(downloadUrl);
            if (response.status === 200) {
                window.open(downloadUrl, '_blank');
                console.log("present")
            } else {
                // alert("not present")
                this.props.hoc.customAlert("Invoice not yet generated.", false)
                console.log("not present")
            }
        } catch (error) {
            window.open(downloadUrl, '_blank');
            // Handle network or other errors
            console.log("error");
        }


        // alert(order_id);
    }

    clearFilter = () => {

        this.setState({
            callcenterdetails: null,
            admin: null,
            totalRevenue: 0,
            storeSearchData: null,
            customerid: "",
            scallcenterid: "",
            callcenterList: [],
            callcenterinfo: "",
            callcenterdetailsList: [],
            countries: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedTown: "",
            selectedDoctor: "",
            fromdate: "",
            todate: "",
            currentDate: this.getCurrentDate(),
            doctorList: [],
            isSearch: false,
            isShowCancelModal: false,
            cancelOrderId: null,
            cancelReason: "",
            mobileno: "",
            summaryDetails: null,
            setorderStatus: "",
            exportData: null,
            fileName: "Callcenter_Data",
            selectedStockist: "",
            clickType: ""
        })
        const callcenterdetails = this.props.admin;
        const callcenter_id = callcenterdetails._id;
        let details = {
            callcenter_id: callcenter_id
        };

        const storeSearchData = this.state.storeSearchData;

        storeSearchData.callcenterdetails = null;
        storeSearchData.admin = null;
        storeSearchData.storeSearchData = null;
        storeSearchData.customerid = "";
        storeSearchData.scallcenterid = "";
        storeSearchData.callcenterList = [];
        storeSearchData.callcenterinfo = "";
        storeSearchData.callcenterdetailsList = [];
        storeSearchData.countries = [];
        storeSearchData.towns = [];
        storeSearchData.showTowns = false;
        storeSearchData.selectedState = "";
        storeSearchData.selectedTown = "";
        storeSearchData.selectedDoctor = "";
        storeSearchData.fromdate = "";
        storeSearchData.todate = "";
        storeSearchData.currentDate = this.getCurrentDate();
        storeSearchData.doctorList = [];
        storeSearchData.isSearch = false;
        storeSearchData.isShowCancelModal = false;
        storeSearchData.cancelOrderId = null;
        storeSearchData.cancelReason = "";
        storeSearchData.mobileno = "";
        storeSearchData.summaryDetails = null;
        storeSearchData.setorderStatus = "";
        storeSearchData.exportData = null;
        storeSearchData.fileName = "Callcenter_Data";
        storeSearchData.selectedStockist = ""

        this.props.setSearchValue(storeSearchData);

        this.getOrderManagementDetails(details)

        this.getSummary()

        setTimeout(() => {
            this.countTotalRevenue();
            // this.populateStates(countryObj.isoCode)	
        }, 500);
    }

    exportXLSX = (item = null) => {
        if (item && this.pageInfo) {
            console.log(this.pageInfo, "898989898 adkladsjlkj", item, this.state.callcenterdetails);
            const { clickType } = this.state
            let apiData = this.formatData(this.state.callcenterdetailsList, item.id === 1 ? this.pageInfo : null)
            console.log(apiData, "adkladsjlkj");
            setTimeout(() => {
                // const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                // const fileExtension = ".xlsx";
                // const ws = XLSX.utils.json_to_sheet(apiData);
                // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                // const data = new Blob([excelBuffer], { type: fileType });
                // FileSaver.saveAs(data, "Order_Data" + fileExtension);
                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                const fileExtension = ".xlsx";
                const ws = XLSX.utils.json_to_sheet(apiData);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                const data = new Blob([excelBuffer], { type: fileType });

                // Create a link element and trigger the download
                const link = document.createElement("a");
                link.href = URL.createObjectURL(data);
                link.download = clickType == 'registration' ? "Registration_Data" : (clickType == 'customer' ? 'Customer_Data' : "Order_Data") + fileExtension;
                document.body.appendChild(link);
                link.click(); // Trigger the download
                document.body.removeChild(link);
            }, 1000);
        }
    }

    handleEditCallCenter = () => {

    }

    approvedRejectRXN = (details) => {
        StockistService.approvedRejectRXN(details).then(data => {
            console.log(data.data, "approved");
            if (data.data.success) {
                console.log(data.data, "okkkkk approved");
                const stockistdetails = this.props.admin;
                let payload = {
                    callcenter_id: stockistdetails._id,
                    clickType: ""
                }
                this.getOrderManagementDetails(payload)

            }
        }).catch(err => {
            console.log(err, "err")
        })

    }

    handleApprovedRXN = (event) => {
        const stockistdetails = this.props.admin;
        this.props.hoc.customAlert('Are you sure you want to approve RXN?', true, "", true, true, () => {
            const orderId = event.target.id;
            console.log(orderId, "orderId123");

            const filteredItem = this.state.callcenterdetailsList.find(item => item.order_id == orderId);
            let details = {
                order_id: orderId,
                is_approved: "yes",
                type: "admin",
                user_id: stockistdetails._id
            }
            console.log(orderId, "filteredItem orderid")
            console.log(this.state.callcenterdetailsList, "filteredItem alldata");
            console.log(filteredItem, "filteredItem");
            if (orderId) {
                this.approvedRejectRXN(details);
                this.props.hoc.customAlert("RXN approved successfully.", true);
            }
        }, () => {
            const orderId = event.target.id;
            console.log(orderId, "orderId123");
            const filteredItem = this.state.callcenterdetailsList.find(item => item.order_id == orderId);
            let details = {
                order_id: orderId,
                is_approved: "no",
                type: "admin",
                user_id: stockistdetails._id
            }
            console.log(orderId, "filteredItem orderid")
            console.log(this.state.callcenterdetailsList, "filteredItem alldata");
            console.log(filteredItem, "filteredItem");
            if (orderId) {
                this.approvedRejectRXN(details);
                this.props.hoc.customAlert("RXN rejected successfully.", true);
            }
        }, "Approve", "Reject")
    }

    handleMarkAsDelivered = (event) => {
        const orderId = event.target.id;
        this.setState({ isMarkAsDelivered: true, selectedMarkAsDeliveredID: orderId }); // Explicitly set to true to open modal
    }

    closeMarkAsDeliveredModal = () => {
        this.setState({ isMarkAsDelivered: false }); // Explicitly set to false to close modal
    };

    handleMarkAsDeliveredFile = (event) => {
        const file = event.target.files[0];
        this.setState({ selectedMarkAsDeliveredFile: file });
    }

    handleMarkAsDeliveredSubmit = () => {
        const { selectedMarkAsDeliveredFile, selectedMarkAsDeliveredID, selectedOrderStatus, remark } = this.state;
        // if (selectedMarkAsDeliveredFile) {
        let fileType = "";
        let fileSizeMB = 0;
        let fileName = "";
        if (selectedMarkAsDeliveredFile) {
            fileType = selectedMarkAsDeliveredFile.type;
            fileSizeMB = selectedMarkAsDeliveredFile.size / (1024 * 1024);
            fileName = selectedMarkAsDeliveredFile.name.split('.')[0];
        }

        if (selectedOrderStatus == 'delivered' && fileType !== 'image/jpeg' && fileType !== 'image/png' && fileType !== 'application/pdf') {
            this.props.hoc.customAlert('Only JPG, PNG, and PDF files are allowed.', false, "", true, true, () => {
                this.setState({ selectedMarkAsDeliveredFile: null })
            }, () => {
            })
            return;
        }
        else if (selectedOrderStatus == 'delivered' && fileSizeMB > 25) {
            this.props.hoc.customAlert('File size must be less than 25MB.', false, "", true, true, () => {
                this.setState({ selectedMarkAsDeliveredFile: null })
            }, () => {
            })
            return;
        } else {
            const stockist_details = this.props.admin;
            const stockistFulDetails = this.state.callcenterdetailsList
            console.log(stockist_details, "stockist_details123");
            console.log(stockistFulDetails, "stockist_details1234");
            // return
            const stockist_id = stockist_details._id;
            let details = {
                callcenter_id: stockist_id
            };
            // Rename the file
            let renamedFile = null;
            if (selectedMarkAsDeliveredFile) {
                renamedFile = new File(
                    [selectedMarkAsDeliveredFile],
                    `${selectedMarkAsDeliveredID}.${selectedMarkAsDeliveredFile.name.split('.').pop()}`, // Keep the original file extension
                    { type: selectedMarkAsDeliveredFile.type }
                );

            } else {
                renamedFile = null
            }
            const formData = new FormData();
            formData.append("file", renamedFile);
            formData.append("order_id", selectedMarkAsDeliveredID);
            formData.append("orderId", stockistFulDetails[0]._id);
            formData.append("user_id", stockist_details._id);
            formData.append("customer_id", stockistFulDetails[0].customer._id);
            formData.append("orderStatus", selectedOrderStatus);
            formData.append("remark", remark);
            formData.append("type", 'admin');
            StockistService.changeOrderDeliveryStatus(formData).then(data => {
                console.log(data.data, "approved");
                if (data.data.success) {
                    console.log(data.data, "okkkkk approved");
                    const stockistdetails = this.props.admin;
                    let payload = {
                        callcenter_id: stockistdetails._id
                    }
                    this.getOrderManagementDetails(payload)
                    // this.props.hoc.customAlert("Order status changed successfully.", true);
                    this.props.hoc.customAlert('Status Changed successfully.', true, "", true, true, () => {
                        this.setState({ isMarkAsDelivered: false })
                    }, () => {
                    })
                }
            }).catch(err => {
                this.props.hoc.customAlert('Something went wrong.', false, "", true, true, () => {
                    this.setState({ selectedMarkAsDeliveredFile: null })
                }, () => {
                })
                console.log(err, "err")
            })
        }
        // }

        // else {
        //     this.props.hoc.customAlert("Please select a file to upload invoice.", false);
        //     return;
        // }
    }

    handleChangeDeliveryStatus = (event) => {
        this.setState({ selectedOrderStatus: event.target.value });
    }

    changeVal = (event) => {
        this.setState({ remark: event.target.value })
    }

    render = () => {
        const { exportData, fileName, countries, states, towns, isLoading, showTowns, selectedState, selectedTown, fromdate, todate, doctorList, selectedDoctor } = this.state.storeSearchData;
        let _data = this.state.finalListData
        // console.log(_data, "_data_data");
        if (_data.length > 0 || this.state.callcenterdetailsList.length == 0) {
            CallCenterService.hideLoader();
        } else {
            CallCenterService.showLoader();
        }
        const { summaryCols, summaryDetails, totalRevenue, allStockists, selectedStockist, exportDropdown, selectedExportItem, clickType, isMarkAsDelivered, selectedMarkAsDeliveredID, selectedMarkAsDeliveredFile, selectedOrderStatus, remark } = this.state;
        console.log(this.state.storeSearchData, "storeSearchData");
        console.log(clickType, 'clickType')
        return (
            <>
                <div className="layout-wrapper">
                    <AdminHeader />
                    <AdminSidebar />
                    <div className="main-content">
                        <div className="page-content">
                            <div className="container-fluid">
                                <div className="row g-4">
                                    <div className="landing-form-dtls">
                                        <div className="row mb-25">
                                            <div className="col-md-6">
                                                <h5 className="mb-20">Customer & Order Details</h5>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="flotright" style={{ width: "180px" }}>
                                                    {/* <ExportToExcel apiData={exportData} fileName={fileName} /> */}
                                                    {
                                                        exportDropdown.length &&
                                                        <Dropdown
                                                            items={exportDropdown}
                                                            onSelect={(item) => {
                                                                console.log(item, "kasghak");
                                                                this.exportXLSX(item)
                                                            }}
                                                            value={selectedExportItem}
                                                            isExport={true}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-30 mb-20 ">
                                            <div className={clickType == '' ? `col-md-2 mb-3` : `col-md-3 mb-3`}>
                                                <label htmlFor className="form-label lvl-clr-hd">{clickType == '' ? 'Order From Date' : 'From Date'}</label>

                                                <DatePicker
                                                    selected={fromdate}
                                                    onChange={(date) => this.changeValFromDate(date)}
                                                    dateFormat="dd-MM-yyyy" // Set the desired display format
                                                    className="form-control name-pt-ft"
                                                    placeholderText="DD-MM-YYYY"
                                                />
                                            </div>
                                            <div className="col-md-2 mb-3">
                                                <label htmlFor className="form-label lvl-clr-hd">{clickType == '' ? 'Order To Date' : 'To Date'}</label>

                                                <DatePicker
                                                    selected={todate}
                                                    onChange={(date) => this.changeValToDate(date)}
                                                    dateFormat="dd-MM-yyyy" // Set the desired display format
                                                    className="form-control name-pt-ft"
                                                    placeholderText="DD-MM-YYYY"
                                                    minDate={fromdate}
                                                />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">State </label>
                                                {
                                                    states.length ?
                                                        <Dropdown
                                                            items={states}
                                                            onSelect={(item) => {
                                                                if (item) {

                                                                    this.setState({ selectedState: item.name })
                                                                    const storeSearchData = this.state.storeSearchData;
                                                                    storeSearchData.selectedState = item.name;
                                                                    this.props.setSearchValue(storeSearchData);

                                                                    if (item.name != "All") {
                                                                        this.populateTowns(item.countryCode, item.isoCode)
                                                                    } else {
                                                                        this.setState({ towns: [] })
                                                                        this.setState({ selectedTown: "" })
                                                                        const storeSearchData = this.state.storeSearchData;
                                                                        storeSearchData.towns = [];
                                                                        storeSearchData.selectedTown = "";
                                                                        this.props.setSearchValue(storeSearchData);
                                                                    }

                                                                }
                                                            }}
                                                            value={selectedState}
                                                        /> :
                                                        <Dropdown
                                                            items={states}
                                                            value={selectedState}
                                                        />
                                                }
                                                {/* <input type="text" value={state} onChange={(event) => this.changeAddr(event)} name="state" id className="form-control name-pt-ft" placeholder /> */}
                                            </div>
                                            <div className="col-md-3 mb-3">
                                                <label htmlFor className="form-label lvl-clr-hd">City/Town </label>
                                                {
                                                    towns.length ?
                                                        <Dropdown
                                                            items={towns}
                                                            onSelect={(item) => {
                                                                if (item) {
                                                                    // alert(item.name);
                                                                    // let _registration = this.state.registration
                                                                    // _registration.address["town"] = item.name
                                                                    this.setState({ selectedTown: item.name })
                                                                    const storeSearchData = this.state.storeSearchData;
                                                                    storeSearchData.selectedTown = item.name;
                                                                    this.props.setSearchValue(storeSearchData);
                                                                    //console.log(item, "item")
                                                                }
                                                            }}
                                                            value={selectedTown}
                                                        /> :
                                                        <Dropdown
                                                            items={towns}
                                                            value={selectedTown}
                                                        />
                                                }
                                            </div>
                                            {clickType == '' ? <div className="col-md-3 mb-3">
                                                <label htmlFor className="form-label lvl-clr-hd">Stockists </label>

                                                {
                                                    allStockists.length ?
                                                        <Dropdown
                                                            items={allStockists}
                                                            onSelect={(item) => {
                                                                console.log(item, "kasghak");
                                                                if (item) {
                                                                    this.setState({ selectedStockist: item._id })
                                                                    const storeSearchData = this.state.storeSearchData;
                                                                    storeSearchData["selectedStockist"] = item._id
                                                                    this.props.setSearchValue(storeSearchData)
                                                                }
                                                            }}
                                                            value={selectedStockist}
                                                            isStockist={true}
                                                        /> :
                                                        <Dropdown
                                                            items={allStockists}
                                                            value={selectedStockist}
                                                        />
                                                }

                                                {/* </select> */}

                                            </div> : ""
                                            }
                                            <div className="">
                                                <button type="button" className="landing-success-btn" onClick={(event) => this.searchFilter(event)}>
                                                    Search</button>
                                                <button type="button" className="landing-clear-btn" onClick={(event) => this.clearFilter()}>
                                                    Clear Filter</button>
                                            </div>

                                        </div>
                                        <Summary
                                            columns={summaryCols}
                                            data={[{
                                                totalNoOfCustomer: summaryDetails ? summaryDetails.totalNoOfCustomer || 0 : 0,
                                                totalNoOfReg: summaryDetails ? summaryDetails.totalNoOfReg || 0 : 0,
                                                totalNoOfOrder: summaryDetails ? summaryDetails.totalNoOfOrder || 0 : 0,
                                                totalNoOfOrderPending: summaryDetails ? summaryDetails.totalNoOfOrderPending || 0 : 0,
                                                totalNoOfOrderDelivered: summaryDetails ? summaryDetails.totalNoOfOrderDelivered || 0 : 0,
                                            }]}
                                            clickFn={this.clickFn}
                                        />

                                        <h5 className="mb-20">{clickType == '' ? `Order Details` : (clickType == 'customer' ? `Customer Details` : `Registration Details`)}</h5>
                                        <div className="table-responsive">


                                            {
                                                _data && _data.length && clickType == '' ?
                                                    <CustomDatatable
                                                        pagination={true}
                                                        columns={[
                                                            {
                                                                name: 'Registration Date',
                                                            },
                                                            {
                                                                name: 'Order Number',
                                                            },
                                                            {
                                                                name: 'Order Date',
                                                            },
                                                            {
                                                                name: 'Patient Name',
                                                            },
                                                            {
                                                                name: 'Patient Age',
                                                            },
                                                            {
                                                                name: 'Patient Gender',
                                                            },
                                                            {
                                                                name: 'Patient Mobile Number',
                                                            },
                                                            {
                                                                name: 'Patient Email',
                                                            },
                                                            {
                                                                name: 'Patient Address',
                                                            },
                                                            {
                                                                name: 'Patient City',
                                                            },
                                                            {
                                                                name: 'Patient State',
                                                            },

                                                            {
                                                                name: 'Patient Pin Code',
                                                            },
                                                            {
                                                                name: 'Dr. Name',
                                                            },
                                                            {
                                                                name: 'Dr. Pin Code',
                                                            },
                                                            {
                                                                name: 'Dr. Registration Number',
                                                            },
                                                            {
                                                                name: 'Dr. Mobile Number',
                                                            },
                                                            {
                                                                name: 'Product Name',
                                                            },
                                                            {
                                                                name: 'Product Quantity',
                                                            },
                                                            {
                                                                name: 'Order Value',
                                                            },
                                                            {
                                                                name: 'Free Gift',
                                                            },
                                                            {
                                                                name: 'Stockist Name',
                                                            },
                                                            {
                                                                name: 'Stockist Code',
                                                            },
                                                            {
                                                                name: 'Payment Status',
                                                            },
                                                            {
                                                                name: 'Prescription',
                                                            },

                                                            {
                                                                name: 'Invoice',
                                                            },
                                                            {
                                                                name: 'Delivery Date',
                                                            },
                                                            {
                                                                name: 'Remark',
                                                            },
                                                            {
                                                                name: 'Order Status',
                                                            },
                                                            {
                                                                name: 'Action',
                                                            }
                                                        ]}
                                                        data={this.state.finalListData}
                                                        pageName="adminlanding"
                                                        handleTrackOrder={this.handleTrackOrder}
                                                        handleEditPatientDetails={this.handleEditPatientDetails}
                                                        handleCancelOrder={this.handleCancelOrder}
                                                        downloadPdf={this.downloadPdf}
                                                        handleDownload={this.handleDownload}
                                                        clickType={clickType}
                                                        handleApprovedRXN={this.handleApprovedRXN}
                                                        handleEditCallCenter={this.handleEditCallCenter}
                                                        handleMarkAsDelivered={this.handleMarkAsDelivered}
                                                        sendPageInfo={(pageInfo) => {
                                                            console.log(pageInfo, "sendPageInfo");
                                                            this.pageInfo = pageInfo
                                                        }}
                                                    />
                                                    :
                                                    (
                                                        _data && _data.length && clickType != '' ?
                                                            <CustomDatatable
                                                                pagination={true}
                                                                columns={[
                                                                    {
                                                                        name: 'Registration Date',
                                                                    },
                                                                    {
                                                                        name: 'Patient Name',
                                                                    },
                                                                    {
                                                                        name: 'Patient Age',
                                                                    },
                                                                    {
                                                                        name: 'Patient Gender',
                                                                    },
                                                                    {
                                                                        name: 'Patient Mobile Number',
                                                                    },
                                                                    {
                                                                        name: 'Patient Email',
                                                                    },
                                                                    {
                                                                        name: 'Patient Address',
                                                                    },
                                                                    {
                                                                        name: 'Patient City',
                                                                    },
                                                                    {
                                                                        name: 'Patient State',
                                                                    },
                                                                    {
                                                                        name: 'Patient Pin Code',
                                                                    },
                                                                    {
                                                                        name: 'Dr. Name',
                                                                    },
                                                                    {
                                                                        name: 'Dr. Pin Code',
                                                                    },
                                                                    {
                                                                        name: 'Dr. Registration Number',
                                                                    },
                                                                    {
                                                                        name: 'Dr. Mobile Number',
                                                                    },
                                                                    // {
                                                                    //     name: 'Action',
                                                                    // }

                                                                ]}
                                                                data={this.state.finalListData}
                                                                pageName="adminlanding"
                                                                handleTrackOrder={this.handleTrackOrder}
                                                                handleEditPatientDetails={this.handleEditPatientDetails}
                                                                handleCancelOrder={this.handleCancelOrder}
                                                                downloadPdf={this.downloadPdf}
                                                                handleDownload={this.handleDownload}
                                                                handleEditCallCenter={this.handleEditCallCenter}
                                                                clickType={clickType}
                                                                sendPageInfo={(pageInfo) => {
                                                                    console.log(pageInfo, "sendPageInfo");
                                                                    this.pageInfo = pageInfo
                                                                }}
                                                            />
                                                            :
                                                            <p className="text-center">No Records Found</p>

                                                    )


                                            }

                                            <OrderCancelModal message="Are you sure want to reject?" handleOkClick={this.handleOkClick} handleRadioClick={this.handleRadioClick} cancelReasonData={this.state.storeSearchData.cancelReason} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {
                            isMarkAsDelivered &&
                            <div
                                className="modal modal-xs fade show d-block"
                                id="orderPlaced"
                                aria-hidden="true"
                                aria-labelledby="paymentSuccessModalLabel"
                                tabIndex={-1}
                                data-keyboard="false"
                                backdrop="static"
                            >
                                <div className="modal-dialog modal-dialog-centered modal-payment-success">
                                    <div className="modal-content border-0">
                                        {/* <div className="modal-check-icon">
                                    <ModalCheckIcon />
                                </div> */}


                                        <div className="modal-header border-0 pt-5 mx-auto">
                                            <h2
                                                className="modal-title fs-5 text-center fw-bold"
                                                id="paymentSuccessModalLabel"
                                            >
                                                Change order status for {selectedMarkAsDeliveredID}
                                            </h2>
                                        </div>
                                        <div className="modal-body">
                                            <div className="mb-3">
                                                <label className="form-label">Choose Status</label>
                                                <div>
                                                    <select className="form-control" onChange={(event) => this.handleChangeDeliveryStatus(event)}>
                                                        <option value="">Select</option>
                                                        <option value="delivered">Order Delivered</option>
                                                        <option value="refunded">Order Refunded</option>
                                                        <option value="Canceled">Order Canceled</option>
                                                    </select>
                                                </div>
                                            </div>

                                            {selectedOrderStatus == 'delivered' ? <div className="mb-3">
                                                <label className="form-label">Choose File</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    onChange={this.handleMarkAsDeliveredFile}
                                                />
                                                <p style={{ color: "red", fontSize: 10 }}>Note: Only JPG, PNG, and PDF files are allowed with maximum size of 25MB</p>
                                                {selectedMarkAsDeliveredFile && (
                                                    <p className="mt-2">
                                                        Selected file: <strong>{selectedMarkAsDeliveredFile.name}</strong>
                                                    </p>
                                                )}
                                            </div> : ""}
                                            <div className="mb-3">
                                                <label className="form-label">Remark</label>
                                                <div>
                                                    <input className="form-control" type="text" name="remark" value={remark} onChange={this.changeVal}></input>
                                                </div>
                                            </div>
                                            <p>Are you sure want to change the status?</p>
                                        </div>
                                        <div className="modal-footer border-0">

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <button
                                                        className="btn bg-primary w-100"
                                                        onClick={this.handleMarkAsDeliveredSubmit}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                                <div className="col-md-6">
                                                    <button
                                                        className="btn bg-primary w-100"
                                                        onClick={this.closeMarkAsDeliveredModal}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <AdminFooter />
                    </div>
                </div>
            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer)),
        setUserType: (userType) => dispatch(CommonAction.setUserType(userType)),
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders)),
        setCallCenterCustomer: (callcentercustomer) => dispatch(CallCenterAction.setCallCenterCustomer(callcentercustomer)),
        setSearchValue: (storeSearchData) => dispatch(AdminAction.setSearchValue(storeSearchData)),
        setActiveDashboardClick: (activeDashboardClick) => dispatch(AdminAction.setActiveDashboardClick(activeDashboardClick)),
        setAdmin: (admin) => dispatch(AdminAction.setAdmin(admin))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, CallCenterReducer, CommonReducer, AdminReducer, StockistReducer }) => {
    const { customer } = CustomerReducer
    const { orders } = OrderReducer
    const { callcenterdetails } = CallCenterReducer
    const { summaryDetails } = CommonReducer
    const { admin, storeSearchData, activeDashboardClick } = AdminReducer
    const { allStockists } = StockistReducer

    console.log(admin, 123);
    console.log(callcenterdetails, 123456);
    console.log(activeDashboardClick, 12345677);

    return {
        // dispatching plain actions
        // OrderListReducer: state.OrderListReducer,
        customer,
        orders,
        callcenterdetails,
        summaryDetails,
        admin,
        storeSearchData,
        allStockists,
        activeDashboardClick
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(OrderManagment)))
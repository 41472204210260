import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from '../../../components/Reusable/WithRouter';
import CustomerAction from "../../../redux/actions/Customer.action";
import CustomerHeader from "../../../components/layout/CustomerHeader";
import CustomerFooter from '../../../components/layout/CustomerFooter';
import ModalCheckIcon from '../../../assets/Svg/ModalCheckIcon';
import BackButton from '../../../components/Reusable/BackButton';
import SuccessFullPaymentModal from '../../../components/Order/SuccessFullPaymentModal';
import OrderPlacedModal from '../../../components/Order/OrderPlacedModal';
import OtherService from '../../../services/OtherService';
import CCAvenueService from '../../../services/CCAvenue.service';
import $ from 'jquery'
import OrderService from '../../../services/Order.service';
import Common from '../../../hoc/Common.hoc';
import OrderAction from '../../../redux/actions/Order.action';
import config from '../../../config/emrok.config';
class OrderPlaced extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentOrder: null,
            is_success: false,
            order_id: "",
            issuccess: null
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            currentOrder: props.currentOrder || null,
            issuccess: props.issuccess || null
        }
    }
    componentDidMount = () => {

        try {
            const orderDetails = this.state.currentOrder;
            let payload = {

                _id: orderDetails ? orderDetails._id : null,
                is_success: this.state.issuccess
            };

            console.log(payload, "payload12336666");
            let order_id = orderDetails ? orderDetails.order_id : ""
            this.setState({ order_id: order_id })
            OrderService.getOrderById(payload).then(res => {
                console.log(res, "resss");
                if (res && res.data && res.data.success && res.data.data) {
                    console.log(this.state.is_success, "this.state.is_success123");
                    if (res.data.data.order[0].payment_status == 'Success') {
                        this.props.setIsSuccess(true)
                        this.setState({ is_success: true });
                    }
                }
            }).catch(err => {
            })

            console.log(this.state.is_success, "this.state.is_success");
        } catch (error) {

        }
        // this.getTransaction()
    }

    handleRedirect = () => {
        this.props.navigate('/order-information');
    }

    render = () => {
        const { is_success, order_id } = this.state
        return (
            <>
                {is_success ? <div className="content">
                    <div className="wrapper-1">
                        <div className="wrapper-2">
                            <h1>Thank you !</h1>
                            <p>Thank you for placing your order. Your payment was successful.</p>
                            <p>Your order no. is - {order_id} </p>
                            <button className="go-home" onClick={this.handleRedirect}>go home</button>
                        </div>
                        <div className="footer-like">

                        </div>
                    </div>
                </div> :

                    <div className="content">
                        <div className="wrapper-1">
                            <div className="wrapper-2">
                                <h1 className='payment_failed_text'>Payment Failed !</h1>
                                <p>Your order is not confirmed due to payment failure.</p>
                                <p>Your order no. is - {order_id}</p>
                                <button className="go-home" onClick={this.handleRedirect}>go home</button>
                            </div>
                            <div className="footer-like">

                            </div>
                        </div>
                    </div>


                }
                <link
                    href="https://fonts.googleapis.com/css?family=Kaushan+Script|Source+Sans+Pro"
                    rel="stylesheet"
                />
            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setIsSuccess: (issuccess) => dispatch(OrderAction.setIsSuccess(issuccess))
    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer }) => {
    const { currentOrder, issuccess } = OrderReducer

    console.log(currentOrder, issuccess, "55555");
    return {
        currentOrder,
        issuccess
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(OrderPlaced)))
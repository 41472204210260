import React from "react";
import { withRouter } from "../../../components/Reusable/WithRouter";
import { connect } from "react-redux";
import Common from "../../../hoc/Common.hoc";
import CustomerFooter from "../../../components/layout/CustomerFooter";
import DoctorHeader from "../../../components/layout/DoctorPersona/DoctorHeader";
import DoctorService from "../../../services/Doctor.service";
import DoctorAction from "../../../redux/actions/DoctorPersona.action";
import { format } from 'date-fns';
import $ from "jquery";
import CustomDatatable from "../../../components/Datatable/CustomDatatable.component";
import moment from "moment";
import OtherService from "../../../services/OtherService";
import OrderAction from "../../../redux/actions/Order.action";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import CountryService from '../../../services/Country.service';
import Dropdown from '../../../components/SearchableDropdown/Dropdown.component';
import CommonAction from "../../../redux/actions/Common.action";
import Summary from "../../../components/Summary/Summary.component";
import StockistService from "../../../services/Stockist.service";
import DoctorPersonaAction from "../../../redux/actions/DoctorPersona.action";
import config from "../../../config/emrok.config";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import CustomerAction from "../../../redux/actions/Customer.action";
class DoctorPersonaDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            doctordetails: null,
            doctorid: "",
            doctordetailsList: [],
            doctorinfo: "",
            countries: [],
            states: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedTown: "",
            selectedDoctor: "",
            fromdate: "",
            todate: "",
            currentDate: this.getCurrentDate(),
            isSearch: false,
            setorderStatus: "",
            allStockists: [],
            storeSearchData: null,
            selectedStockist: "",
            exportDropdown: [
                {
                    id: 1,
                    label: "Export Current View",
                    value: 1
                },
                {
                    id: 2,
                    label: "Export All Data",
                    value: 2
                }
            ],
            selectedExportItem: null,

        }
        this.copyState = this.state
        this.pageInfo = null
    }

    static getDerivedStateFromProps(props, state) {
        return {
            doctordetails: props._id || null,
            summaryDetails: props.summaryDetails || null,
            allStockists: props.allStockists || [],
            storeSearchData: props.storeSearchData || null
        }
    }

    getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // getSummary = async (doctor_id = null) => {
    //     if (doctor_id) {

    //         const { fromdate, todate, selectedState, selectedTown, setorderStatus } = this.state;
    //         const selectedMomentFrom = moment(fromdate);
    //         const selectedOnlyDateFrom = selectedMomentFrom.format('YYYY-MM-DD');

    //         const nextDayMomentFrom = selectedMomentFrom.clone().add(1, 'day');
    //         const nextDayOnlyDateFrom = nextDayMomentFrom.format('YYYY-MM-DD');

    //         const selectedMomentTo = moment(todate);
    //         const selectedOnlyDateTo = selectedMomentTo.format('YYYY-MM-DD');

    //         const nextDayMomentTo = selectedMomentTo.clone().add(1, 'day');
    //         const nextDayOnlyDateTo = nextDayMomentTo.format('YYYY-MM-DD');
    //         let payload = {
    //             type: "DOCTOR",
    //             id: doctor_id,
    //             fromDate: fromdate ? selectedOnlyDateFrom : "",
    //             toDate: todate ? selectedOnlyDateTo : "",
    //             stateName: "",
    //             town: "",
    //             orderStatus: setorderStatus
    //         };
    //         console.log(payload, "payload12333");
    //         OtherService.getSummary(payload).then(data => {
    //             console.log(data, "getSummary");
    //         }).catch(err => {
    //             console.log(err, "getSummary err");
    //         })
    //     }
    // }

    // clickFn = async (index) => {
    //     const doctordetails = this.props.doctordetails;
    //     let payload = {};
    //     if (doctordetails) {
    //         const { fromdate, todate, selectedState, selectedTown, setorderStatus } = this.state;
    //         const selectedMomentFrom = moment(fromdate);
    //         const selectedOnlyDateFrom = selectedMomentFrom.format('YYYY-MM-DD');
    //         // Calculate the next day using moment
    //         const nextDayMomentFrom = selectedMomentFrom.clone().add(1, 'day');
    //         const nextDayOnlyDateFrom = nextDayMomentFrom.format('YYYY-MM-DD');

    //         const selectedMomentTo = moment(todate);
    //         const selectedOnlyDateTo = selectedMomentTo.format('YYYY-MM-DD');
    //         // Calculate the next day using moment
    //         const nextDayMomentTo = selectedMomentTo.clone().add(1, 'day');
    //         const nextDayOnlyDateTo = nextDayMomentTo.format('YYYY-MM-DD');
    //         if (index === 0) {
    //             payload = {
    //                 doctor_id: doctordetails._id,
    //                 fromDate: "",
    //                 toDate: "",
    //                 stateName: "",
    //                 town: "",
    //                 orderStatus: ""
    //             };
    //         }
    //         else if (index === 1) {
    //             payload = {
    //                 doctor_id: doctordetails._id,
    //                 fromDate: fromdate ? selectedOnlyDateFrom : "",
    //                 toDate: todate ? selectedOnlyDateTo : "",
    //                 stateName: "",
    //                 town: "",
    //                 orderStatus: setorderStatus
    //             };
    //             payload["orderStatus"] = await "complete"
    //             this.setState({ setorderStatus: "complete" })
    //         }
    //         this.getDoctorDetails(payload)
    //     }

    // }
    getDoctorDetails = (details) => {
        this.setState({ doctordetailsList: [] }, () => {
            DoctorService.getdoctorPersonaDetails(details).then(data => {
                console.log(data, "doctordetailsList");
                if (data.data.success && data.data.data.mrinfo !== '') {
                    this.setState({ doctordetailsList: data.data.data });
                }
            }).catch(err => {
                console.log(err, "err")
            })
        });
    }
    componentDidMount() {

        const doctordetails = this.props.doctorPersonaDetails;
        console.log(doctordetails, "doctordetails list");
        if (doctordetails) {
            const doctor_id = doctordetails._id;
            let details = {
                doctor_id: doctor_id
            };
            // this.getSummary(doctor_id)
            this.getDoctorDetails(details)
            DoctorService.getdoctorPersonaInfo(details).then(data => {
                console.log(data, "personaInfo");
                if (data.data.success && data.data.data !== '') {
                    this.setState({ doctorinfo: data.data.data[0] });
                }
            }).catch(err => {
                console.log(err, "err")
            })
        }
        this.getStockists();
        setTimeout(() => {
            this.populateStates("IN")
            // this.populateStates(countryObj.isoCode)	
        }, 500);
    }

    changeValFromDate = async (date) => {
        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "fromdate");
        this.setState({ fromdate: date });
    }

    changeValToDate = async (date) => {
        const formatedDate = moment(date).format('DD-MM-YYYY');
        console.log(date, "todate");
        this.setState({ todate: date });
    }
    setOrderHistoryData = (data) => {
        let resp = []
        for (let i = 0; i < data.length; i++) {
            const materials = data[i].products.map(product => product.name);
            const totalqty = data[i].products.reduce((totalQuantity, product) => totalQuantity + product.quantity, 0);
            const materialString = materials.join(', ');
            resp.push({
                _id: data[i]._id,
                customer_id: data[i].customer._id,
                customer_name: data[i].customer.name,
                customer_mobile: data[i].customer.mobileno,
                customer_address: (data[i].address ? data[i].address.addressLine1 : ""),
                customer_state: (data[i].address ? data[i].address.state : ""),
                customer_city: (data[i].address ? data[i].address.town : ""),
                customer_age: (data[i].customer ? data[i].customer.age : ""),
                customer_gender: (data[i].customer ? data[i].customer.gender.charAt(0).toUpperCase() + data[i].customer.gender.slice(1).toLowerCase() : ""),
                customer_email: (data[i].customer ? data[i].customer.email : ""),
                order_id: data[i].order_id,
                allproducts: data[i].products,
                order_status: data[i].orderStatus ? data[i].orderStatus : "",
                order_date: data[i].createdAt ? data[i].createdAt : "",
                delivery_date: data[i].deliveryDate ? data[i].deliveryDate : "",
                doctor_name: data[i].doctor.drName,
                doctor_registration_no: data[i].doctor.drRegNo,
                doctor_mobileno: data[i].doctor.mobileno,
                stockist_name: data[i].stockist ? data[i].stockist.name : "",
                stockist_code: data[i].stockist ? data[i].stockist.stockist_code : "",
                product_code: materialString,
                total_product_qty: totalqty + (data[i].free_pen_quantity ? data[i].free_pen_quantity : 0),
                total: data[i].total ? data[i].total : "",
                prescription_path: data[i].prescription ? data[i].prescription.filepath : "",
                fileExtention: data[i].prescription ? data[i].prescription.filepath.split('.')[1] : "",
                payment_status: data[i].payment_status ? data[i].payment_status.charAt(0).toUpperCase() + data[i].payment_status.slice(1).toLowerCase() : "",
                payment_type: data[i].payment_type ? data[i].payment_type : "",
                registration_no: data[i].customer.patient_id || "",
                registration_date: data[i].customer.createdAt || "",
                customer_pin: data[i].address.pin || "",
                doctor_pin: data[i].doctor.pin || "",
                free_pen: data[i].free_pen_quantity || 0,
                invoice: ""
            })
        }
        return resp
    }
    formatData(auditdata = null, pageInfo = null) {
        console.log(auditdata, auditdata.length, "auditdata")
        let formatedArr = [];
        for (let i = 0; i < auditdata.length; i++) {
            const materials = auditdata[i].products.map(product => product.name);
            // Create a comma-separated string from the "material" values
            const materialString = materials.join(', ');
            const totalqty = auditdata[i].products.reduce((totalQuantity, product) => totalQuantity + product.quantity, 0);
            console.log(materials, materialString, totalqty, "popopopopo")
            // const feedbackStringFinal = allfeedback.length > 0 ? allfeedback.join(', ') : "";
            console.log(auditdata, "jhjhjauditdatahjhjh");
            try {

                formatedArr.push({
                    "Registration Date": auditdata[i].customer.createdAt ? moment(auditdata[i].customer.createdAt).format('DD-MM-YYYY') : "",
                    "Order Number": auditdata[i].order_id || "",
                    "Order Date": auditdata[i].createdAt ? moment(auditdata[i].createdAt).format('DD-MM-YYYY') : "",
                    // "Registration No.": auditdata[i].customer.patient_id || "",
                    "Patient Name": auditdata[i].customer.name || "",
                    "Patient Age": auditdata[i].customer.age || "",
                    "Patient Gender": auditdata[i].customer.gender ? auditdata[i].customer.gender.charAt(0).toUpperCase() + auditdata[i].customer.gender.slice(1).toLowerCase() : "",
                    "Patient Mobile Number": auditdata[i].customer.mobileno || "",
                    "Patient Email": auditdata[i].customer.email || "",
                    "Patient Address": auditdata[i].address.addressLine1 || "",
                    "Patient City": auditdata[i].address.town || "",
                    "Patient State": auditdata[i].address.state || "",
                    "Patient Pin Code": auditdata[i].address.pin || "",
                    "Dr. Name": auditdata[i].doctor.drName || "",
                    "Dr. Pin Code": auditdata[i].doctor.pin || "",
                    "Dr. Registration Number": auditdata[i].doctor.drRegNo || "",
                    "Dr. Mobile Number": auditdata[i].doctor.mobileno || "",
                    "Product Name": materialString,
                    "Product Quantity": totalqty + (auditdata[i].free_pen_quantity ? auditdata[i].free_pen_quantity : 0),
                    "Order Value": auditdata[i].total || "",
                    "Free Gift": auditdata[i].free_pen_quantity || 0,
                    "Stockist Name": auditdata[i].stockist ? auditdata[i].stockist.name : "" || "",
                    "Stockist Code": auditdata[i].stockist ? auditdata[i].stockist.stockist_code : "" || "",
                    "Payment Status": auditdata[i].payment_status ? auditdata[i].payment_status.charAt(0).toUpperCase() + auditdata[i].payment_status.slice(1).toLowerCase() : "",
                    // "Prescription": auditdata[i].prescription ? auditdata[i].prescription.filepath : "",
                    // "Invoice": "",
                    "Delivery Date": auditdata[i].deliveryDate ? moment(auditdata[i].deliveryDate).format('DD-MM-YYYY') : "",
                    "Order Status": auditdata[i].orderStatus ? auditdata[i].orderStatus.charAt(0).toUpperCase() + auditdata[i].orderStatus.slice(1).toLowerCase() : ""
                })
                console.log(formatedArr, 8787878787);
            } catch (error) {
                console.log(error, 8787878787);
            }


        }
        console.log(formatedArr, "formatedArr111");
        if (pageInfo && formatedArr.length) {
            let response = []
            const limit = pageInfo.rowsPerPage;
            const offset = (pageInfo.currentPage - 1) * pageInfo.rowsPerPage;
            response = formatedArr.slice(offset, offset + limit)
            return response
        }

        return formatedArr;


    }

    searchFilter = (event) => {
        this.setState({ doctordetailsList: [] });
        const doctordetails = this.props.doctorPersonaDetails;
        console.log(doctordetails, "doctordetails list");
        const doctor_id = doctordetails._id;
        const { selectedState, selectedTown, selectedDoctor, setorderStatus, selectedStockist } = this.state.storeSearchData;
        const { fromdate, todate } = this.state

        console.log(fromdate, todate, "details");
        const selectedOnlyDateFrom = fromdate ? moment(fromdate).format('YYYY-MM-DD') : "";
        const selectedOnlyDateTo = todate ? moment(todate).format('YYYY-MM-DD') : "";
        let details = {
            doctor_id: doctor_id,
            fromDate: fromdate ? selectedOnlyDateFrom : "",
            toDate: todate ? selectedOnlyDateTo : "",
            stateName: selectedState,
            town: selectedTown,
            stockist_id: selectedStockist
        };

        console.log(details, "details12333");

        if (fromdate != "" && todate == "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else if (fromdate == "" && todate != "") {
            this.props.hoc.customAlert("Please select both fromdate and todate", false);
        } else {
            DoctorService.getdoctorPersonaDetails(details).then(data => {
                console.log(data, "doctordetailsList");
                if (data.data.success && data.data.data.mrinfo !== '') {
                    this.setState({ doctordetailsList: data.data.data, isSearch: true });
                }
            }).catch(err => {
                console.log(err, "err")
            })
            // DoctorService.getdoctorInfo(details).then(data => {
            //     console.log(data.data, "doctor info");
            //     if (data.data.success && data.data.data.mrinfo !== '') {
            //         console.log(data.data.data, "okkkkk");

            //         this.setState({ doctordetailsList: data.data.data });
            //         this.setState({ isSearch: true })
            //         this.getSummary(doctor_id)
            //     } else {
            //         this.getSummary(doctor_id)
            //     }
            //     // this.setState({mrdetailsList:data.data.data.mrdetails});
            // }).catch(err => {
            //     console.log(err, "err")
            // })
        }
        console.log("search filter");
    }
    componentWillUnmount() {
        if (this.dataTable) {
            this.dataTable.destroy(true);
        }
    }
    handleTrackOrder = (event) => {
        // const allOrder = this.props.OrderReducer.allOrders;
        const targetId = event.target.name;
        console.log(targetId, "target ID");
        try {
            OtherService.getOrderDetails({ _id: targetId }).then(data => {
                console.log(data.data, "order details 123456789");
                if (data.data.success) {
                    console.log(data.data.data.order, "okkkkk 123456789");
                    this.props.saveSelectedOrders(data.data.data.order)
                    this.props.navigate('/doctor-persona/track-info-order');
                    this.props.setUserType('doctor-persona');
                    // this.setState({mrdetailsList:data.data.data.mrdetails});
                }
            })
        } catch (error) {

        }
    }

    populateStates = (isoCode = null) => {
        if (isoCode) {
            CountryService.getStates(isoCode).then(data => {
                //console.log(data, "states")
                if (data.success) {
                    const storeSearchData = this.state.storeSearchData;
                    storeSearchData.states = data.states;
                    console.log(storeSearchData.states, 6666666);
                    this.props.setSearchValue(storeSearchData);
                    this.setState({ states: data.states, towns: [] }, async () => {
                        this.setState({ showTowns: false })
                        let _state = this.state.storeSearchData.selectedState || null
                        let _states = this.state.storeSearchData.states || []
                        console.log(_state, this.state.storeSearchData.states, "jhgjhgjhg");
                        if (_state) {
                            // alert("ok");
                            let stateObj = null
                            let stateObjAll = null
                            for (let i = 0; i < this.state.storeSearchData.states.length; i++) {
                                if (this.state.states[i].name === _state) {
                                    stateObj = this.state.storeSearchData.states[i]
                                }
                            }
                            console.log(stateObj, _state, 1234545677);
                            console.log("stateObj found", stateObj)
                            // if (stateObj) {
                            setTimeout(() => {
                                if (stateObj != null && stateObj.countryCode)
                                    this.populateTowns(stateObj.countryCode, stateObj.isoCode)
                            }, 500);
                            // }
                        }
                    })


                    setTimeout(() => {
                        let stateDetails = this.state.storeSearchData.states;

                        const findIfExistAll = stateDetails.find(item => item.name === 'All');

                        console.log(findIfExistAll, 33333);

                        if (!findIfExistAll) {
                            console.log(stateDetails, "details state all 1")
                            const allOption = {
                                "name": "All",
                                "isoCode": null,
                                "countryCode": null,
                                "latitude": null,
                                "longitude": null,
                                "label": "All",
                                "value": "all"
                            };
                            console.log(this.state.storeSearchData.states, "details state")
                            // Create a new array with the "All" option at the beginning
                            let optionsWithAll = [allOption, ...stateDetails];
                            console.log(optionsWithAll, "details state all")
                            this.setState({ states: optionsWithAll })
                            const storeSearchData = this.state.storeSearchData;
                            storeSearchData.states = optionsWithAll;
                            this.props.setSearchValue(storeSearchData);
                        }

                    }, 500);
                }
            })
        }
    }

    populateTowns = (countryCode = null, stateCode = null) => {
        console.log(countryCode, stateCode, "countryCode stateCode")
        if (countryCode && stateCode) {
            CountryService.getTowns(countryCode, stateCode).then(data => {
                console.log(data, "towns")
                // setTimeout(() => {
                if (data.success) {
                    const storeSearchData = this.state.storeSearchData;
                    storeSearchData.towns = data.towns;
                    this.props.setSearchValue(storeSearchData);
                    this.setState({ towns: data.towns }, () => this.setState({ showTowns: true }))

                    setTimeout(() => {
                        let stateDetails = this.state.storeSearchData.towns;
                        const allOption = {
                            "name": "All",
                            "isoCode": null,
                            "countryCode": null,
                            "latitude": null,
                            "longitude": null,
                            "label": "All",
                            "value": "all"
                        };
                        console.log(this.state.storeSearchData.towns, "details state")
                        // Create a new array with the "All" option at the beginning
                        let optionsWithAll = [allOption, ...stateDetails];
                        console.log(optionsWithAll, "details state all")
                        this.setState({ towns: optionsWithAll })

                        const storeSearchData = this.state.storeSearchData;
                        storeSearchData.towns = optionsWithAll;
                        this.props.setSearchValue(storeSearchData);
                    }, 500);

                    // this.props.setSearchValue(this.state);
                }
                // }, 2000);
            })
        }
    }
    getStockists = () => {
        StockistService.getAll().then(data => {
            console.log(data, "getStockists");
        })
    }
    handleEditPatientDetails = async (event) => {
        const { clickType } = this.state
        event.preventDefault();
        const customer_id = event.target.id;
        const callCenterDetails = this.state.doctordetailsList;
        const callcenterLoginDetails = this.props.doctorPersonaDetails;
        console.log(customer_id, callCenterDetails, "selectedPatientDetails99999");
        const selectedPatientDetails = await callCenterDetails.filter(item => item._id == customer_id)
        console.log(selectedPatientDetails, "selectedPatientDetails with doctor25252525");
        // return
        if (selectedPatientDetails.length) {
            let customerDetailsObjNew = {};
            const allPrescription = selectedPatientDetails[0].prescription ? selectedPatientDetails[0].prescription : null
            customerDetailsObjNew = {
                _id: selectedPatientDetails[0].customer._id,
                customer_code: null,
                country_code: "+91",
                mobileno: selectedPatientDetails[0].customer.mobileno,
                age: selectedPatientDetails[0].customer.age,
                email: selectedPatientDetails[0].customer.email,
                gender: selectedPatientDetails[0].customer.gender,
                name: selectedPatientDetails[0].customer.name,
                drName: selectedPatientDetails[0].doctor ? selectedPatientDetails[0].doctor.drName : "",
                drRegistrationNo: selectedPatientDetails[0].doctor ? selectedPatientDetails[0].doctor.drRegNo : "",
                drPin: selectedPatientDetails[0].doctor ? selectedPatientDetails[0].doctor.pin : "",
                drmobileno: selectedPatientDetails[0].doctor ? selectedPatientDetails[0].doctor.mobileno : "",
                drState: selectedPatientDetails[0].doctor ? selectedPatientDetails[0].doctor.drState : "",
                drCity: selectedPatientDetails[0].doctor ? selectedPatientDetails[0].doctor.drCity : "",
                address: {
                    _id: selectedPatientDetails[0].address ? selectedPatientDetails[0].address._id : "",
                    country: selectedPatientDetails[0].address ? selectedPatientDetails[0].address.country : "",
                    addressLine1: selectedPatientDetails[0].address ? selectedPatientDetails[0].address.addressLine1 : "",
                    addressLine2: selectedPatientDetails[0].address ? selectedPatientDetails[0].address.addressLine2 : "",
                    town: selectedPatientDetails[0].address ? selectedPatientDetails[0].address.town : "",
                    state: selectedPatientDetails[0].address ? selectedPatientDetails[0].address.state : "",
                    pin: selectedPatientDetails[0].address ? selectedPatientDetails[0].address.pin : "",
                },
                doctor_id: selectedPatientDetails[0].doctor ? selectedPatientDetails[0].doctor._id : "",
                consent: selectedPatientDetails[0].customer.consent ? selectedPatientDetails[0].customer.consent : false,
                prescription: allPrescription,
                order_id: selectedPatientDetails[0]._id
            };

            this.props.setCustomer(customerDetailsObjNew);
            this.props.setDoctorPersona(callcenterLoginDetails);
            // console.log(this.props.callcenterdetails, "callcenter reducer")
            // this.props.setCallCenterCustomer(selectedPatientDetails[0].customer)
        } else {

        }

        this.props.navigate('/doctor-persona/registration-details-edit');
        // console.log(selectedPatientDetails);
    }

    handleDownload = async (event) => {

        const selectOrder = event.target.id;

        const downloadUrl = config.serviceUrl + "/images/sap/in/Invoice/" + selectOrder + ".pdf"; // Replace with the actual URL or path of the file

        try {
            const response = await fetch(downloadUrl);
            if (response.status === 200) {
                window.open(downloadUrl, '_blank');
                console.log("present")
            } else {
                // alert("not present")
                this.props.hoc.customAlert("Invoice not yet generated.", false)
                console.log("not present")
            }
        } catch (error) {
            window.open(downloadUrl, '_blank');
            // Handle network or other errors
            console.log("error");
        }

    };
    exportXLSX = (item = null) => {
        if (item && this.pageInfo) {
            console.log(this.pageInfo, "898989898 adkladsjlkj", item, this.state.doctordetails);
            let apiData = this.formatData(this.state.doctordetailsList, item.id === 1 ? this.pageInfo : null)
            console.log(apiData, "adkladsjlkj");
            setTimeout(() => {
                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                const fileExtension = ".xlsx";
                const ws = XLSX.utils.json_to_sheet(apiData);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                const data = new Blob([excelBuffer], { type: fileType });

                // Create a link element and trigger the download
                const link = document.createElement("a");
                link.href = URL.createObjectURL(data);
                link.download = "Order_Data" + fileExtension;
                document.body.appendChild(link);
                link.click(); // Trigger the download
                document.body.removeChild(link);
            }, 1000);
        }
    }
    clearFilter = () => {
        this.setState({
            doctordetails: null,
            doctorid: "",
            doctordetailsList: [],
            doctorinfo: "",
            countries: [],
            towns: [],
            showTowns: false,
            selectedState: "",
            selectedTown: "",
            selectedDoctor: "",
            fromdate: "",
            selectedStockist: "",
            todate: "",
            currentDate: this.getCurrentDate(),
            isSearch: false,
            setorderStatus: "",
            allStockists: [],
            storeSearchData: null,
            selectedExportItem: null,

        });
        const callcenterdetails = this.props.doctorPersonaDetails;
        const callcenter_id = callcenterdetails._id;
        let details = {
            callcenter_id: callcenter_id
        };
        const storeSearchData = this.state.storeSearchData;
        storeSearchData.doctordetails = null;
        storeSearchData.doctorid = "";
        storeSearchData.storeSearchData = null;
        storeSearchData.doctordetailsList = [];
        storeSearchData.doctorinfo = "";
        storeSearchData.countries = [];
        storeSearchData.towns = [];
        storeSearchData.showTowns = false;
        storeSearchData.selectedState = "";
        storeSearchData.selectedTown = "";
        storeSearchData.selectedDoctor = "";
        storeSearchData.selectedStockist = "";
        storeSearchData.fromdate = "";
        storeSearchData.todate = "";
        storeSearchData.currentDate = this.getCurrentDate();
        storeSearchData.isSearch = false;
        storeSearchData.setorderStatus = "";
        storeSearchData.allStockists = [];
        storeSearchData.selectedExportItem = null
        this.props.setSearchValue(storeSearchData);
        this.getDoctorDetails(details)

    }
    render = () => {

        let _data = this.setOrderHistoryData(this.state.doctordetailsList)
        const { countries, states, towns, isLoading, showTowns, selectedState, selectedTown } = this.state.storeSearchData;
        const { allStockists, selectedStockist, exportDropdown, selectedExportItem, fromdate, todate } = this.state;
        return (

            <>

                <section className="landing-profl-detls">
                    <div className="container">
                        <DoctorHeader />
                        <div className="row">
                            <div className="landing-page-pt pt-3 pb-4">
                                <div className="container">
                                    <div className="mr-landing-page">
                                        <div className="landing-head-pt">
                                            {/* <h2>Call Center</h2> */}
                                            <h5>Welcome {this.state.doctorinfo?.name} to Diabetes Golden Forum</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="landing-form-dtls mt-20">
                            <div className="row mb-25">
                                <div className="col-md-6">
                                    {/* <h5 className="mb-20">Reject Order Details</h5> */}
                                </div>
                                <div className='col-md-6'>
                                    <div className="flotright marginright" style={{ width: "180px" }}>
                                        {/* <ExportToExcel apiData={exportData} fileName={fileName} /> */}
                                        {
                                            exportDropdown.length &&
                                            <Dropdown
                                                items={exportDropdown}
                                                onSelect={(item) => {
                                                    console.log(item, "kasghak");
                                                    this.exportXLSX(item)
                                                }}
                                                value={selectedExportItem}
                                                isExport={true}
                                            />
                                        }
                                    </div>
                                </div>


                            </div>
                            <div className="row mt-30 mb-20 ">
                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Order From Date</label>
                                    <DatePicker
                                        selected={fromdate}
                                        onChange={(date) => this.changeValFromDate(date)}
                                        dateFormat="dd-MM-yyyy" // Set the desired display format
                                        className="form-control name-pt-ft"
                                        placeholderText="DD-MM-YYYY"
                                    />
                                </div>
                                <div className="col-md-2 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Order To Date</label>
                                    <DatePicker
                                        selected={todate}
                                        onChange={(date) => this.changeValToDate(date)}
                                        dateFormat="dd-MM-yyyy" // Set the desired display format
                                        className="form-control name-pt-ft"
                                        placeholderText="DD-MM-YYYY"
                                        minDate={this.state.fromdate}
                                    />
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor="disabledTextInput" className="form-label lvl-clr-hd">State </label>
                                    {
                                        states.length ?
                                            <Dropdown
                                                items={states}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        this.setState({ selectedState: item.name })
                                                        const storeSearchData = this.state.storeSearchData;
                                                        storeSearchData.selectedState = item.name;
                                                        this.props.setSearchValue(storeSearchData);

                                                        if (item.name != "All") {
                                                            this.populateTowns(item.countryCode, item.isoCode)
                                                        } else {
                                                            this.setState({ towns: [] })
                                                            this.setState({ selectedTown: "" })
                                                            const storeSearchData = this.state.storeSearchData;
                                                            storeSearchData.towns = [];
                                                            storeSearchData.selectedTown = "";
                                                            this.props.setSearchValue(storeSearchData);
                                                        }

                                                    }
                                                }}
                                                value={selectedState}
                                            /> :
                                            <Dropdown
                                                items={states}
                                                value={selectedState}
                                            />
                                    }
                                    {/* <input type="text" value={state} onChange={(event) => this.changeAddr(event)} name="state" id className="form-control name-pt-ft" placeholder /> */}
                                </div>
                                <div className="col-md-3 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">City/Town </label>
                                    {
                                        towns.length ?
                                            <Dropdown
                                                items={towns}
                                                onSelect={(item) => {
                                                    if (item) {
                                                        // alert(item.name);
                                                        // let _registration = this.state.registration
                                                        // _registration.address["town"] = item.name
                                                        this.setState({ selectedTown: item.name })
                                                        const storeSearchData = this.state.storeSearchData;
                                                        storeSearchData.selectedTown = item.name;
                                                        this.props.setSearchValue(storeSearchData);
                                                        //console.log(item, "item")
                                                    }
                                                }}
                                                value={selectedTown}
                                            /> :
                                            <Dropdown
                                                items={towns}
                                                value={selectedTown}
                                            />
                                    }
                                    {/* <input type="text" value={town} onChange={(event) => this.changeAddr(event)} name="town" id className="form-control name-pt-ft" placeholder /> */}
                                </div>

                                <div className="col-md-3 mb-3">
                                    <label htmlFor className="form-label lvl-clr-hd">Stockists </label>

                                    {
                                        allStockists.length ?
                                            <Dropdown
                                                items={allStockists}
                                                onSelect={(item) => {
                                                    console.log(item, "kasghak");
                                                    if (item) {
                                                        this.setState({ selectedStockist: item._id })
                                                        const storeSearchData = this.state.storeSearchData;
                                                        storeSearchData["selectedStockist"] = item._id
                                                        this.props.setSearchValue(storeSearchData)
                                                    }
                                                }}
                                                value={selectedStockist}
                                                isStockist={true}
                                            /> :
                                            <Dropdown
                                                items={allStockists}
                                                value={selectedStockist}
                                            />
                                    }
                                </div>

                                <div className="">
                                    <button type="button" className="landing-success-btn" onClick={(event) => this.searchFilter(event)}>
                                        Search</button>
                                    <button type="button" className="landing-clear-btn" onClick={(event) => this.clearFilter()}>
                                        Clear Filter</button>
                                </div>

                                <h5 className="mb-20 mt-4">Reject Order Details</h5>
                            </div>
                            {
                                _data && _data.length ?
                                    <CustomDatatable
                                        pagination={true}
                                        columns={[
                                            {
                                                name: 'Registration Date',
                                            },
                                            {
                                                name: 'Order Number',
                                            },
                                            {
                                                name: 'Order Date',
                                            },
                                            {
                                                name: 'Patient Name',
                                            },
                                            {
                                                name: 'Patient Age',
                                            },
                                            {
                                                name: 'Patient Gender',
                                            },
                                            {
                                                name: 'Patient Mobile Number',
                                            },
                                            {
                                                name: 'Patient Email',
                                            },
                                            {
                                                name: 'Patient Address',
                                            },
                                            {
                                                name: 'Patient City',
                                            },
                                            {
                                                name: 'Patient State',
                                            },

                                            {
                                                name: 'Patient Pin Code',
                                            },
                                            {
                                                name: 'Dr. Name',
                                            },
                                            {
                                                name: 'Dr. Pin Code',
                                            },
                                            {
                                                name: 'Dr. Registration Number',
                                            },
                                            {
                                                name: 'Dr. Mobile Number',
                                            },
                                            {
                                                name: 'Product Name',
                                            },
                                            {
                                                name: 'Product Quantity',
                                            },
                                            {
                                                name: 'Order Value',
                                            },
                                            {
                                                name: 'Free Gift',
                                            },
                                            {
                                                name: 'Stockist Name',
                                            },
                                            {
                                                name: 'Stockist Code',
                                            },
                                            {
                                                name: 'Payment Status',
                                            },
                                            {
                                                name: 'Prescription',
                                            },

                                            {
                                                name: 'Invoice',
                                            },
                                            {
                                                name: 'Delivery Date',
                                            },
                                            {
                                                name: 'Order Status',
                                            },
                                            {
                                                name: 'Action',
                                            }

                                        ]}
                                        data={this.setOrderHistoryData(this.state.doctordetailsList)}
                                        pageName="doctorlanding"
                                        handleTrackOrder={this.handleTrackOrder}
                                        handleEditPatientDetails={this.handleEditPatientDetails}
                                        handleDownload={this.handleDownload}
                                        sendPageInfo={(pageInfo) => {
                                            console.log(pageInfo, "sendPageInfo");
                                            this.pageInfo = pageInfo
                                        }}
                                    />
                                    : <p className="text-center">No Records Found</p>
                            }


                        </div>

                        <CustomerFooter />
                    </div>
                </section>


            </>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCustomer: (customer) => dispatch(CustomerAction.setCustomer(customer)),
        setUserType: (userType) => dispatch(CommonAction.setUserType(userType)),
        saveSelectedOrders: (selectedOrders) => dispatch(OrderAction.saveSelectedOrders(selectedOrders)),
        setSearchValue: (storeSearchData) => dispatch(DoctorPersonaAction.setSearchValue(storeSearchData)),
        setDoctorPersona: (doctorPersonaDetails) => dispatch(DoctorPersonaAction.setDoctorPersona(doctorPersonaDetails))

    }
}

const mapStateToProps = ({ CustomerReducer, OrderReducer, DoctorPersonaReducer, CommonReducer, StockistReducer }) => {
    const { customer } = CustomerReducer
    const { orders } = OrderReducer
    const { doctorPersonaDetails, storeSearchData } = DoctorPersonaReducer
    const { summaryDetails } = CommonReducer
    const { allStockists } = StockistReducer

    console.log(doctorPersonaDetails, 123);

    return {
        customer,
        orders,
        doctorPersonaDetails,
        storeSearchData,
        summaryDetails,
        allStockists
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Common(DoctorPersonaDetails)))
import React from "react";
import {
  BrowserRouter,
} from "react-router-dom";
import Login from "../screens/Login/Login.screen";
import Registration from "../screens/Customer/Registration/Registration";
import PageNotFound from "../screens/PageNotFound/PageNotFound.screen";
import OrderPlaced from "../screens/Order/OrderPlaced/OrderPlaced";
import PlaceOrder from "../screens/Order/PlaceOrder/PlaceOrder";
import {
  loginGuard,
  customerLoginGuard,
  userLoginGuard
} from "../middleware/guard";
import OrderInformation from "../screens/Order/OrderInformation/OrderInformation";
import {
  useGuardedRoutes,
  GuardConfigProvider,
  GuardProvider,
} from "react-router-guarded-routes";
import FeedbackScreen from "../screens/Feedback/Feedback.screen";
import FeedbackSubmitScreen from "../screens/Feedback/FeedbackSubmit.screen";
import OrderHistory from "../screens/Order/OrderHistory/OrderHistory.screen";
import OrderHistoryTracking from "../screens/Order/OrderHistory/OrderHistoryTracking.screen";
import PrivacyPolicy from "../screens/PrivacyPolicy/PrivacyPolicy.screen";
import OrderInfo from "../screens/Order/OrderInformation/OrderInfo";
import OrderInfoTracking from "../screens/Order/OrderInformation/OrderInfoTracking";
import FeedbackListScreen from "../screens/Feedback/FeedbackList.screen";
import LoginBaseScreen from "../screens/Login/LoginBase.screen";
import LoginCustomerTrackingScreen from "../screens/Login/LoginCustomerTracking.screen";
import CallCenterListScreen from "../screens/CallCenter/List/CallCenterList.screen";
import TrackInfoOrder from "../screens/Order/TrackOrder/TrackInfoOrder";
import TrackOrderByOrderId from "../screens/Order/TrackOrder/TrackOrderByOrderId";
import RegistrationScreenCallCenter from "../screens/CallCenter/List/Registration";
import StockistListScreen from "../screens/Stockist/List/StockistList.screen";
import DoctorDetailsScreen from "../screens/Doctor/List/DoctorPersonaDetails.screen";
import UploadNewPrescriptionScreen from "../screens/Upload/UploadPrescription.screen";
import AuditPageScreen from "../screens/CallCenter/List/AuditList.screen";
import AdminDashboardScreen from "../screens/Admin/Dashboard.screen";
import AuditTrial from "../screens/Admin/AuditTrial.screen";
import OrderManagment from "../screens/Admin/OrderManagment/OrderManagment.screen";
import TrackOrderAdmin from "../screens/Admin/OrderManagment/TrackOrder.screen";
import PatientDetailsAdmin from "../screens/Admin/OrderManagment/PatientDetails.screen";
import QrCodeGenerationScren from "../screens/Admin/Qr/QrCodeGeneration.screen";
import UserManagmentScreen from "../screens/Admin/UserManagment/UserManagmentDashboard.screen";
import StockistManagementScreen from "../screens/Admin/UserManagment/StockistManagementDashboard.screen";
import CallCenterManagementScreen from "../screens/Admin/UserManagment/CallCenterManagementDashboard.screen";
import CallCenterEditScreen from "../screens/Admin/UserManagment/CallCenterEdit.screen";
import CallCenterAddScreen from "../screens/Admin/UserManagment/CallCenterAdd.screen";
import StockistEditScreen from "../screens/Admin/UserManagment/StockistEdit.screen";
import ProductManagementScreen from "../screens/Admin/ProductManagement/productManagement.screen";
import ProductEdit from "../screens/Admin/ProductManagement/ProductEdit.screen";
import Concent from "../screens/Concent.screen";
import AdminUserManagementScreen from "../screens/Admin/UserManagment/AdminUser/AdminUserManagmentDashboard.screen";
import AdminUserEditScreen from "../screens/Admin/UserManagment/AdminUser/AdminUserEdit.screen";
import AdminUserAddScreen from "../screens/Admin/UserManagment/AdminUser/AdminUserAdd.screen";
import RegistrationDetails from "../screens/Admin/RegistrationDetails";
import DoctorPersonaListScreen from "../screens/Admin/UserManagment/DoctorPersona/DoctorPersonaList.screen";
import DoctorPersonaAddScreen from "../screens/Admin/UserManagment/DoctorPersona/DoctorPersonaAdd.screen";
import DoctorPersonaEditScreen from "../screens/Admin/UserManagment/DoctorPersona/DoctorPersonaEdit.screen";

const Test = () => (
  <>
    <div>test</div>
  </>
);

const RouterArr = () => {
  const routes = [
    {
      path: "/",
      children: [
        {
          path: "",
          guards: [loginGuard],
          element: <Login />,
        },
        {
          path: "registration",
          guards: [customerLoginGuard],
          element: <Registration />,
        },
        {
          path: "payment",
          guards: [customerLoginGuard],
          element: <OrderPlaced />,
        },
        {
          path: "place-order",
          guards: [customerLoginGuard],
          element: <PlaceOrder />,
        },
        {
          path: "order-information",
          guards: [customerLoginGuard],
          element: <OrderInformation />,
        },
        {
          path: "order-info",
          guards: [customerLoginGuard],
          element: <OrderInfo />,
        },
        {
          path: "feedback-info",
          guards: [customerLoginGuard],
          element: <FeedbackScreen />,
        },
        // {
        //   path: "feedback",
        //   guards: [customerLoginGuard],
        //   element: <FeedbackFromOrderScreen />,
        // },
        {
          path: "orders",
          guards: [customerLoginGuard],
          element: <OrderHistory />,
        },
        {
          path: "order-history",
          guards: [customerLoginGuard],
          element: <OrderHistoryTracking />,
        },
        {
          path: "upload-new-prescription",
          guards: [customerLoginGuard],
          element: <UploadNewPrescriptionScreen />,
        },
        {
          path: "order-info-tracking",
          guards: [customerLoginGuard],
          element: <OrderInfoTracking />,
        },
        {
          path: "feedback-submit",
          guards: [customerLoginGuard],
          element: <FeedbackSubmitScreen />,
        },
        {
          path: "privacy-policy",
          guards: [customerLoginGuard],
          element: <PrivacyPolicy />,
        },
        {
          path: "concent",
          guards: [customerLoginGuard],
          element: <Concent />,
        },
        {
          path: "feedback-list",
          guards: [customerLoginGuard],
          element: <FeedbackListScreen />,
        },
        {
          path: "track-order-info/:unique_id",
          guards: [customerLoginGuard],
          element: <TrackOrderByOrderId />,
        },
      ],
    },
    {
      path: "/stockist/",
      exact: true,

      children: [
        {
          path: "list",
          exact: true,
          guards: [userLoginGuard],
          element: <StockistListScreen />,
        },
        {
          path: "track-info-order",
          guards: [userLoginGuard],
          exact: true,
          element: <TrackInfoOrder />,
        },
      ],
    },
    ,
    {
      path: "/callcenter/",
      exact: true,

      children: [
        {
          path: "list",
          exact: true,
          guards: [userLoginGuard],
          element: <CallCenterListScreen />,
        },
        {
          path: "track-info-order",
          guards: [userLoginGuard],
          exact: true,
          element: <TrackInfoOrder />,
        },
        {
          path: "patient-details",
          guards: [userLoginGuard],
          exact: true,
          element: <RegistrationScreenCallCenter />,
        },
        {
          path: "audit-page",
          guards: [userLoginGuard],
          exact: true,
          element: <AuditPageScreen />,
        },
        {
          path: "registration-details-edit",
          guards: [userLoginGuard],
          element: <RegistrationDetails />,
        },
      ],
    },
    {
      path: "/doctor-persona/",
      exact: true,

      children: [
        {
          path: "dashboard",
          exact: true,
          guards: [userLoginGuard],
          element: <DoctorDetailsScreen />,
        },
        {
          path: "track-info-order",
          guards: [userLoginGuard],
          exact: true,
          element: <TrackInfoOrder />,
        },
        {
          path: "registration-details-edit",
          guards: [userLoginGuard],
          exact: true,
          element: <RegistrationDetails />,
        },
      ],
    },
    {
      path: "/admin/",
      exact: true,

      children: [
        {
          path: "dashboard",
          exact: true,
          guards: [userLoginGuard],
          element: <AdminDashboardScreen />,
        },
        {
          path: "audit-trial",
          exact: true,
          guards: [userLoginGuard],
          element: <AuditTrial />,
        },
        {
          path: "order-managment",
          exact: true,
          guards: [userLoginGuard],
          element: <OrderManagment />,
        },
        {
          path: "track-order",
          exact: true,
          guards: [userLoginGuard],
          element: <TrackOrderAdmin />,
        },
        {
          path: "patient-details",
          exact: true,
          guards: [userLoginGuard],
          element: <PatientDetailsAdmin />,
        },
        {
          path: "qr-code-generation",
          exact: true,
          guards: [userLoginGuard],
          element: <QrCodeGenerationScren />,
        },
        {
          path: "user-managment",
          exact: true,
          guards: [userLoginGuard],
          element: <UserManagmentScreen />,
        },
        {
          path: "user-managment/stockist",
          exact: true,
          guards: [userLoginGuard],
          element: <StockistManagementScreen />,
        },
        {
          path: "user-managment/callcenter",
          exact: true,
          guards: [userLoginGuard],
          element: <CallCenterManagementScreen />,
        },
        {
          path: "user-managment/callcenter/details",
          exact: true,
          guards: [userLoginGuard],
          element: <CallCenterEditScreen />,
        },
        {
          path: "user-managment/callcenter/add",
          exact: true,
          guards: [userLoginGuard],
          element: <CallCenterAddScreen />,
        },
        {
          path: "user-managment/stockist/edit",
          exact: true,
          guards: [userLoginGuard],
          element: <StockistEditScreen />,
        },
        {
          path: "user-managment/admin-users",
          exact: true,
          guards: [userLoginGuard],
          element: <AdminUserManagementScreen />,
        },
        {
          path: "user-managment/admin-user/details",
          exact: true,
          guards: [userLoginGuard],
          element: <AdminUserEditScreen />,
        },
        {
          path: "user-managment/admin-user/add",
          exact: true,
          guards: [userLoginGuard],
          element: <AdminUserAddScreen />,
        },

        {
          path: "user-managment/doctor-persona",
          exact: true,
          guards: [userLoginGuard],
          element: <DoctorPersonaListScreen />,
        },
        {
          path: "user-managment/doctor-persona/add",
          exact: true,
          guards: [userLoginGuard],
          element: <DoctorPersonaAddScreen />,
        },
        {
          path: "user-managment/doctor-persona/edit",
          exact: true,
          guards: [userLoginGuard],
          element: <DoctorPersonaEditScreen />,
        },

        {
          path: "product-managment/list",
          exact: true,
          guards: [userLoginGuard],
          element: <ProductManagementScreen />,
        },
        {
          path: "product-managment/edit",
          exact: true,
          guards: [userLoginGuard],
          element: <ProductEdit />,
        },
        {
          path: "product-managment/add",
          exact: true,
          guards: [userLoginGuard],
          element: <ProductEdit />,
        },
        {
          path: "registration-details-edit",
          guards: [userLoginGuard],
          element: <RegistrationDetails />,
        },
      ],
    },
    {
      path: "/user",
      children: [
        { path: "", guards: [userLoginGuard], element: <LoginBaseScreen /> },
        { path: "test", element: <Test /> },
      ],
    },
    {
      path: "/login",
      element: <LoginCustomerTrackingScreen />,
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ];
  // const location = useLocation();
  const element = useGuardedRoutes(routes); // Using your guarded routes
  return element;
  // Get the base URL
  // const baseUrl = window.location.origin;

  // Construct the full URL
  // const fullUrl = `${baseUrl}${location.pathname}${location.search}${location.hash}`;

  // Log the current routes and the full URL
  // console.log('Current routes:', routes);
  // console.log('Current Full URL:', fullUrl); // Log the full URL
  return routes;
};

const Router = () => {
  return (
    <BrowserRouter>
      <GuardConfigProvider>
        <GuardProvider>
          <RouterArr />
        </GuardProvider>
      </GuardConfigProvider>
    </BrowserRouter>
  );
};

export default Router;
